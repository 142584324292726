import React, { useState, useEffect, useContext } from 'react';
import { getClientDomain, getToken, round, formatter, translate, setStatusName, formatDatePicker, setStatusTypePackageName, handlerColorPackagesEstatus } from '../../../../utils/Common';
import { useForm } from "react-hook-form";
import AuthContext from '../../../../contexts/auth';
import ModalContext from '../../../../contexts/modal';
import PlatformList from '../../../../components/PlatformList';
import { FaBox, FaUndo } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import { BsPeopleFill } from 'react-icons/bs';
import ClientsSearchList from '../../../../components/ClientsSearchList';

// formulario con los detalles del paquete
const PackageInfo = (props) => {
    const userContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext);
    const { register, errors, handleSubmit, setError, clearErrors, reset } = useForm();

    // enviar update al servidor
    const sendUpdatePackageInfo = async (data) => {
        try {
            let dataToSend = {
                courier: props.selectedPackage.Courier,
                estatus: props.selectedPackage.Estatus,
                numeroPaquete: props.selectedPackage.Nro_Id,
                numeroTracking: props.selectedPackage.NumeroTracking,
                idUsuario: props.selectedPackage.IdUsuario,
                idPlataforma: props.selectedPackage.IdPlataforma,
                idPlataformaDet: props.selectedPackage.IdPlataformaDet,
                email: props.selectedPackage.Email,
                descripcion: props.selectedPackage.Descripcion,
                clasificacion: props.selectedPackage.Clasificacion,
                alto: props.selectedPackage.Alto,
                largo: props.selectedPackage.Largo,
                ancho: props.selectedPackage.Ancho,
                peso: props.selectedPackage.PesoLibras,
                costoProv: props.selectedPackage.CostoProv,
                precioBase: props.selectedPackage.PrecioBase,
                cargoAduana: props.selectedPackage.CargoAduana,
                otrosCargos: props.selectedPackage.OtrosCargos,
                itbms: props.selectedPackage.Itbms,
                precio: props.selectedPackage.Precio,
                dateExpiration: props.selectedPackage.FechaExpiracion,
                packageId: props.selectedPackage.IdPaquete,
                actionSelect: props.optionSelected,
                idCliente :  props.selectedPackage.IdCliente
            }

            if (props.optionSelected === "ChangeInfo") {
                dataToSend.email = data.email;
                dataToSend.descripcion = data.descripcion;
                dataToSend.clasificacion = valueClasificacion;
                dataToSend.alto = round(Number(data.alto) * 2.54, 2);
                dataToSend.largo = round(Number(data.largo) * 2.54, 2);
                dataToSend.ancho = round(Number(data.ancho) * 2.54, 2);
                dataToSend.peso = Number(data.peso);
                dataToSend.precio = round(Number(data.precio), 2);
                if (userContext.user?.IsAdmin) {
                    dataToSend.costoProv = round(Number(data.costoProv), 2);
                    dataToSend.precioBase = round(Number(data.precioBase), 2);
                    dataToSend.cargoAduana = round(Number(data.cargoAduana), 2);
                    dataToSend.otrosCargos = round(Number(data.otrosCargos), 2);    
                    dataToSend.precio = round(Number(precio), 2);
                    dataToSend.itbms = round(Number(itbms), 2);
                }
            }
            else if (props.optionSelected === "ChangePlatform") {
                dataToSend.idPlataforma = data.plataforma;
                dataToSend.idPlataformaDet = data.idPlataformaDet;
                dataToSend.clasificacion = handlerGetClasificacion(dataToSend.clasificacion);
            }           
            else if (props.optionSelected === "ChangeClient") {
                if (!selectedClient) {
                    setError('buscarCliente', {
                        type: '',
                        message: translate("dashboard.packages.loadpackage.searchClient.inputData")
                    });
                    return;
                }

                if(selectedClient === dataToSend.idCliente ){
                    setError('buscarCliente', {
                        type: '',
                        message: translate("global.messageClientSelected")
                    });
                    return;
                }
                
                dataToSend.idCliente = selectedClient;

            } else
            if (props.optionSelected === "ChangeDateExpiration") {
                dataToSend.dateExpiration = data.dateExpiration;
            }      
            
            props.onClickPanelOption("");
            const response = await fetch(`${getClientDomain(2)}/dashboard/packages/update${props.optionSelected === "ChangeDateExpiration" ? "/dateExpiration" : ""}` , {
                method: 'PATCH',
                connection: 'keep-alive',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(dataToSend)
            });
            const result = await response.json();

            if (props.optionSelected === "ChangeClient") {
                props.handleOpenModalChangeClient(); // cerrar modal de ChangeClient    
            }

            if (result.code === 200) {
                if (result.message === 'PACKAGE_UPDATED') {
                    props.getInfoPaquete(props.selectedPackage);
                    modalContext.confirm(translate("global.alerts.successTransact"),
                        <span>{translate("dashboard.packages.details.info.PACKAGE_UPDATED")}</span>,
                        <button type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                        true);
                }
                else {
                    modalContext.confirm(translate("global.alerts.ops"),
                        <span>{translate("dashboard.packages.details.info.PACKAGE_UPDATED_ERROR")}</span>,
                        <button type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                        true);
                    props.onClickPanelOption("");
                }
            }
            else {
                modalContext.confirm(translate("global.alerts.ops"),
                    <span>{translate("dashboard.packages.details.info.PACKAGE_UPDATED_ERROR")}</span>,
                    <button type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                    true);
                props.onClickPanelOption("");
            }
        } catch (err) {
            console.log(err);
        }
    }

    // obtener la clasificacion
    const handlerGetClasificacion = dimension => {
        switch (dimension) {
            case 'S':
                return 'P';
            case 'M':
                return 'M';
            case 'L':
                return 'G';
            case 'XL':
                return 'XG'

            // CASE ALTERNATIVE
            case 'A':
                return 'P';
            case 'B':
                return 'M';
            case 'C':
                return 'G';
            case 'D':
                return 'XG'

            default:
                break;
        }
    }
// P, M, G, XG - DAshboard - S, M, L, XL - DB

    // si cancelo la operacion, volver a los valores iniciales
    const cancelOption = () => {
        setCostoProv(props.selectedPackage.CostoProv);
        setPrecioBase(props.selectedPackage.PrecioBase);
        setCargoAduana(props.selectedPackage.CargoAduana);
        setOtrosCargos(props.selectedPackage.OtrosCargos);
        setItbms(props.selectedPackage.Itbms);
        setSelectedClient(null);
        clearErrors();
        props.onClickPanelOption("");
    }

    let clasificacionList = ['P', 'M', 'G'];
    const [costoProv, setCostoProv] = useState((userContext.user?.IsAdmin && props.selectedPackage.CostoProv) ? Number(props.selectedPackage.CostoProv) : 0);
    const [precioBase, setPrecioBase] = useState(userContext.user?.IsAdmin ? Number(props.selectedPackage.PrecioBase) : Number(props.selectedPackage.CostoProv));
    const [cargoAduana, setCargoAduana] = useState((userContext.user?.IsAdmin && props.selectedPackage.CargoAduana) ? Number(props.selectedPackage.CargoAduana) : 0);
    const [otrosCargos, setOtrosCargos] = useState((userContext.user?.IsAdmin && props.selectedPackage.OtrosCargos) ? Number(props.selectedPackage.OtrosCargos) : 0);
    const [itbms, setItbms] = useState((userContext.user?.IsAdmin && props.selectedPackage.IdTipoUsuario === 1 && props.selectedPackage.Itbms) ? Number(props.selectedPackage.Itbms) : 0);
    useEffect(() => {
        if (userContext.user?.IsAdmin && props.selectedPackage.IdTipoUsuario === 1) {
            let total = Number(precioBase) + Number(cargoAduana) + Number(otrosCargos);
            setItbms(round(total * 0.07, 2));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [precioBase, cargoAduana, otrosCargos]);
    const [precio, setPrecio] = useState(props.selectedPackage.Precio);
    useEffect(() => {
        let total = Number(precioBase) + Number(cargoAduana) + Number(otrosCargos) + Number(itbms);
        setPrecio(round(total, 2));
    }, [precioBase, cargoAduana, otrosCargos, itbms]);
    
    // variables usadas para la busqueda del cliente
 
    const [selectedClient, setSelectedClient] = useState(null);
    const [valueClasificacion, setValueClasificacion] = useState(props.selectedPackage?.Clasificacion || "");

    const renderModalChangeClient = (
        <>
           <Modal show={props.isOpenModalChangeClient} onHide={props.handleOpenModalChangeClient}>
      <Modal.Header closeButton>
        <Modal.Title>
        <BsPeopleFill  size={20} />
        <span className="ml-2">{translate("global.changeClient")}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{textAlign:"left"}}>
            <span>{translate("global.currentClient")}:</span>
            <input className="form-control form-control-md font-small" disabled value={props.selectedPackage?.IdCliente+" - "+props.selectedPackage?.Cliente}/>
        </div>
        <div className='pt-3' style={{textAlign:"left"}}>
            <span>{translate("global.newClient")}:</span>
             {/* <SearchClient
    idUsuarioSelected={props.selectedPackage?.IdUsuario}
    errors={errors}
    setError={setError}
    clearErrors={clearErrors}
    register={register}
    idTipoCliente={ props.selectedPackage?.IdTipoUsuario > 1 ? 7 : 1 }
    buscarCliente={buscarCliente}
    handleBuscarCliente={setBuscarCliente}
    selectClientes={selectClientes}
    handleSelectClientes={setSelectClientes}
    handleSelectedClient={handleSelectedClient}
    />  */}

    <ClientsSearchList  
    idUsuarioSelected={props.selectedPackage?.IdUsuario}
    errors={errors}
    setError={setError}
    clearErrors={clearErrors}
    register={register}
    idTipoCliente={ props.selectedPackage?.IdTipoUsuario > 1 ? 7 : 1 }
    setSelectOption={setSelectedClient}
    autoSelectClient
    />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleOpenModalChangeClient}>
          {translate("global.close")}
        </Button>
        <Button variant="primary"  className="btn-fucsia" onClick={() => sendUpdatePackageInfo()}>
        {translate("global.save")}
        </Button>
      </Modal.Footer>
    </Modal>
        </>
    ) 

    useEffect(() => {
        reset();
        setSelectedClient(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.optionSelected]);

    return  <form className="package-info m-0 p-0 text-left font-small" onSubmit={ handleSubmit(sendUpdatePackageInfo) }>
                <div className="form-row mx-2">
                    <div className="form-group col-12 mb-2 py-1 text-center bg-blue">
                        <h6 className="text-light mb-0">{translate("dashboard.packages.details.infoPackage")}</h6>
                    </div>
                </div>
                
                <div className="form-row mx-2">
                    <div className={ "form-group mb-2" + (userContext.user?.IdTipoUsuario !== 1 ? " col-2 col-md-1" : " col-2 col-md-2") }>
                        <label className="mb-1" htmlFor="inputIdPaquete">ID Boxit:</label>
                        <input type="text" value={ props.selectedPackage?.IdPaquete ? props.selectedPackage.IdPaquete : '' } readOnly className="form-control-sm font-small form-control-plaintext font-bold" id="inputIdPaquete"/>
                    </div>

                    <div className={ "form-group mb-2" + (userContext.user?.IdTipoUsuario !== 1 ? " col-5 col-md-3" : " col-5 col-md-5") }>
                        <label className="mb-1" htmlFor="inputNumeroPaquete">{translate("dashboard.packages.details.numPackage")}</label>
                        <input type="text" value={ props.selectedPackage?.Nro_Id ? props.selectedPackage.Nro_Id : '' } readOnly className="form-control form-control-sm font-small" id="inputNumeroPaquete"/>
                    </div>

                    <div className={ "form-group mb-2" + (userContext.user?.IdTipoUsuario !== 1 ? " col-5 col-md-3" : " col-5 col-md-5") }>
                        <label className="mb-1" htmlFor="inputNumeroPaquete">{translate("dashboard.packages.details.numTracking")}:</label>
                        <input type="text" value={ props.selectedPackage?.NumeroTracking ? props.selectedPackage.NumeroTracking : '' } readOnly className="form-control form-control-sm font-small" id="inputNumeroPaquete"/>
                    </div>

                    <div className={ "form-group mb-2" + (userContext.user?.IdTipoUsuario !== 1 ? " col-2 col-md-1" : " col-2 col-md-2") }>
                        <label className="mb-1" htmlFor="inputIdProveedor">ID Prov:</label>
                        <input type="text" value={ props.selectedPackage?.IdUsuario ? props.selectedPackage.IdUsuario : '' } readOnly className="form-control-plaintext form-control-sm font-small font-bold" placeholder="123456" id="inputIdProveedor"/>
                    </div>

                    <div className={ "form-group mb-2" + (userContext.user?.IdTipoUsuario !== 1 ? " col-5 col-md-4" : " col-5 col-md-3") }>
                        <label className="mb-1" htmlFor="inputProveedor">{translate("global.provider")}:</label>
                        <input type="text" value={ props.selectedPackage?.Proveedor ? props.selectedPackage.Proveedor : '' } readOnly className="form-control form-control-sm font-small" id="inputProveedor"/>
                    </div>

                    <div className={ "form-group mb-2" + (userContext.user?.IdTipoUsuario !== 1 ? " col-2 col-md-2" : " col-4 col-md-2") }>
                        <label className="mb-1" htmlFor="inputPeso">{translate("global.status")}:</label>
                        <input type="text" value={ props.selectedPackage?.Estatus ? setStatusName(props.selectedPackage?.IdEstatus) : "" } readOnly className={ "form-control-plaintext form-control-sm font-small font-bold " + (handlerColorPackagesEstatus(props.selectedPackage?.IdEstatus)) } id="inputEstatusPaquete"/>
                    </div>

                    <div className={ " mb-2 col-md-2" }>
                        <label className="mb-1" htmlFor="inputEstatusTipoPaquete">{translate("global.typeOfPackage")}:</label>
                        {/* <input type="text" value={ props.selectedPackage?.IdTipoPaquete ? setStatusTypePackageName(props.selectedPackage?.IdTipoPaquete) : "" } readOnly className={ "form-control-plaintext form-control-sm font-small font-bold "} id="inputEstatusTipoPaquete"/> */}
                        <p className="mb-2 mt-1"><span className="ml-1" style={{background: props.selectedPackage?.IdTipoPaquete!== 3 ? "#f26c3f" : "#4abfee", padding:"4px 6px", color:"white", borderRadius:"8px"}}> {props.selectedPackage?.IdTipoPaquete!== 3 ? <><FaBox className="mr-1"/>{translate("global.status.typePackage.regular")}</> : <><FaUndo className="mr-1"/>{translate("global.status.typePackage.returned")}</>}</span></p>
                    </div>

                    <div className={ "form-group mb-2" + (userContext.user?.IdTipoUsuario !== 1 ? " col-6 col-md-4" : " col-6 col-md-4") }>
                        <label className="mb-1" htmlFor="inputCliente">{translate("global.client")}:</label>
                        <input type="text" value={ props.selectedPackage?.Cliente ? props.selectedPackage.Cliente : '' } readOnly className="form-control form-control-sm font-small" id="inputCliente"/>
                    </div>

                    { userContext.user?.IdTipoUsuario !== 1 &&
                        <div className="form-group col-6 col-md-4 mb-2">
                            <label className="mb-1" htmlFor="inputEmail">{translate("global.email")}:</label>
                            <input type="email" required 
                                defaultValue={ props.selectedPackage?.Email ? props.selectedPackage.Email.toLowerCase() : '' } 
                                readOnly={ props.optionSelected === "ChangeInfo" ? false : true } 
                                className={ "form-control form-control-sm font-small " + (errors.email ? "is-invalid" : '') } 
                                id="inputEmail" name="email" 
                                ref={ register } />
                        </div>
                    }
                </div>

                {/* { userContext.user?.IsAdmin && props.optionSelected === 'ChangeClient' &&
                    <SearchClient 
                        from={ 'changeClient' }  
                        errors={ errors }
                        setError={ setError }
                        clearErrors={ clearErrors }
                        register={ register }
                        buscarCliente={ buscarCliente }
                        handleBuscarCliente={ setBuscarCliente }
                        selectClientes={ selectClientes }
                        handleSelectClientes={ setSelectClientes }
                        handleSelectedClient={ handleSelectedClient } />
                } */}
                
                <div className="row mx-2">
                    <div className="col-12 col-md-8 mb-0 p-0">
                        <div className="form-row">
                            <div className={ "form-group mb-2 " + (userContext.user?.IdTipoUsuario !== 1 ? "col-9 col-md-9" : "col-12 col-md-12") }>
                                <label className="mb-1" htmlFor="inputDescripcion">{translate("global.description")}:</label>
                                <textarea rows="2"
                                    defaultValue={ props.selectedPackage?.Descripcion ? props.selectedPackage.Descripcion : '' }
                                    readOnly={ props.optionSelected === "ChangeInfo" ? false : true } 
                                    className="form-control form-control-sm font-small" 
                                    id="inputDescripcion" name="descripcion" 
                                    ref={ register } />
                            </div>

                            { userContext.user?.IdTipoUsuario !== 1 &&
                                <div className="form-group col-3 col-md-3 mb-2">
                                    <label className="mb-1" htmlFor="inputClave">{translate("global.key")}:</label>
                                    <input type="text" value={ props.selectedPackage?.Clave ? props.selectedPackage.Clave : "" } readOnly className="form-control-plaintext form-control-sm font-small font-bold" id="inputClave"/>
                                </div>
                            }
                        </div>
            
                        <div className="form-row">
                            <div className="form-group col-3 col-md-3 mb-2">
                                <label className="mb-1">{translate("global.size")}:</label>
                                        <select className="form-control form-control-sm font-small" 
                                            defaultValue={ props.selectedPackage?.Clasificacion } 
                                            // readOnly={ props.optionSelected === "ChangeInfo" ? false : true }
                                            disabled={(props.selectedPackage?.IdEstatus === 3 ? true : false) || (props.optionSelected === "ChangeInfo" ? false : true ) } 
                                            id="selectClasificacion" name="clasificacion" 
                                            ref={ register }
                                            onChange={(e)=> setValueClasificacion(e.target.value)}>
                                            <option defaultValue value={props.selectedPackage?.Clasificacion}>{ handlerGetClasificacion(props.selectedPackage?.Clasificacion) }</option>
                                            { clasificacionList.map((clasif, index) => clasif !== handlerGetClasificacion(props.selectedPackage?.Clasificacion) && <option key={ index }>{clasificacionList[index]}</option>) }
                                        </select>
                            </div>

                            <div className="form-group col-3 col-md-3 mb-2">
                                <label className="mb-1" htmlFor="inputAlto">{translate("global.high")} <span className="font-xxsmall">(inch)</span>:</label>
                                <input type="number" 
                                    defaultValue={ props.selectedPackage?.Alto !== 0 ? round(Number(props.selectedPackage.Alto) / 2.54, 2) : 0 }
                                    readOnly={ props.optionSelected === "ChangeInfo" ? false : true } 
                                    className="form-control form-control-sm font-small" 
                                    id="inputAlto" name="alto" 
                                    ref={ register } />
                            </div>
                            
                            <div className="form-group col-3 col-md-3 mb-2">
                                <label className="mb-1" htmlFor="inputLargo">{translate("global.long")} <span className="font-xxsmall">(inch)</span>:</label>
                                <input type="number" 
                                    defaultValue={ props.selectedPackage?.Largo !== 0 ? round(Number(props.selectedPackage.Largo) / 2.54, 2) : 0 } 
                                    readOnly={ props.optionSelected === "ChangeInfo" ? false : true } 
                                    className="form-control form-control-sm font-small" 
                                    id="inputLargo" name="largo" 
                                    ref={ register } />
                            </div>

                            <div className="form-group col-3 col-md-3 mb-2">
                                <label className="mb-1" htmlFor="inputAncho">{translate("global.width")} <span className="font-xxsmall">(inch)</span>:</label>
                                <input type="number" 
                                    defaultValue={ props.selectedPackage?.Ancho !== 0 ? round(Number(props.selectedPackage.Ancho) / 2.54, 2) : 0 }
                                    readOnly={ props.optionSelected === "ChangeInfo" ? false : true } 
                                    className="form-control form-control-sm font-small"  
                                    id="inputAncho" name="ancho"
                                    ref={ register }/>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className={ "form-group mb-2 " + (props.selectedPackage?.Identificacion ? "col-12 col-md-7" : "col-12 col-md-9") }>
                                <label className="mb-1">{translate("global.platform")}:</label>
                                { props.optionSelected !== "ChangePlatform" ?
                                    <input type="text" 
                                        defaultValue={ props.selectedPackage?.Plataforma }
                                        readOnly
                                        className="form-control form-control-sm font-small" />
                                    :
                                    <PlatformList 
                                        errors={ errors }
                                        register={ register }
                                        nameShow={ props.selectedPackage?.Plataforma }
                                        idShow={ props.selectedPackage?.IdPlataforma }
                                        readOnly={ props.optionSelected === "ChangePlatform" ? false : true } />
                                }
                            </div>
                    
                            { props.selectedPackage?.Identificacion &&
                                <div className="form-group col-6 col-md-2 mb-2">
                                    <label className="mb-1" htmlFor="inputLocker">{translate("global.door")}:</label>
                                    <input type="text" value={ props.selectedPackage?.Identificacion ? props.selectedPackage.Identificacion : "" } readOnly className="form-control form-control-sm font-small" id="inputLocker"/>
                                </div>
                            }

                            <div className="form-group col-6 col-md-3 mb-2">
                                <label className="mb-1" htmlFor="inputPeso">{translate("global.weight")}  <span className="font-xxsmall">(lb)</span>:</label>
                                <input type="number" 
                                    defaultValue={ props.selectedPackage.PesoLibras } 
                                    readOnly={ props.optionSelected === "ChangeInfo" ? false : true } 
                                    className="form-control form-control-sm font-small"  
                                    id="inputPeso" name="peso" 
                                    ref={ register } />
                            </div>

                            <div className="form-group col-12 col-md-6 mt-2">
                                <label className="mb-1" htmlFor="inputPeso">{translate("global.dateExpiration")}: </label>
                                
                                { props.optionSelected === "ChangeDateExpiration" ? (
                                     <input type={"date"} 
                                     defaultValue={  props.selectedPackage?.FechaExpiracion ? formatDatePicker(new Date(props.selectedPackage.FechaExpiracion)) : "" } 
                                     className="form-control form-control-sm font-small"  
                                     name="dateExpiration" 
                                     ref={ register } required/>
                                    ) : (
                                        <input type={"text"} 
                                        defaultValue={  props.selectedPackage?.FechaExpiracion ? formatDatePicker(new Date(props.selectedPackage.FechaExpiracion), 2) : ""} 
                                        readOnly={ true}  
                                        className="form-control form-control-sm font-small"  
                                       />
                                    ) } 
                                
                            </div>

                        </div>
                    </div>

                    {/* {props.isOpenModalChangeClient && renderModalChangeClient } */}

                    <div className="col-12 col-md-4 mb-0 p-0">
                        <table className="table table-borderless text-right">
                            <tbody className="font-small">
                                { userContext.user?.IsAdmin ?
                                <>
                                    <tr className="border-bottom">
                                        <td className="p-1 col-2">{translate("global.cost") + translate("global.provider")} <span className="font-xsmall">(B/)</span>:</td>
                                        <td className="ml-3 p-1 col-1">
                                            { props.optionSelected !== "ChangeInfo" ?
                                                <span className="font-bold text-right" >
                                                    { props.selectedPackage?.CostoProv ? props.selectedPackage?.CostoProv : '0' }
                                                </span>
                                                :
                                                <input type="number"
                                                    step=".01"
                                                    value={ costoProv } 
                                                    onChange={ (e) => setCostoProv(e.target.value) }
                                                    className="form-control-sm font-small text-right form-control"
                                                    id="inputCostoProv" name="costoProv" 
                                                    ref={ register } />
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1 col-2">{translate("global.baseCost")} <span className="font-xsmall">(B/)</span>:</td>
                                        <td className="ml-3 p-1 col-1">
                                            { props.optionSelected !== "ChangeInfo" ?
                                                <span className="font-bold text-right" >
                                                    { props.selectedPackage?.PrecioBase ? props.selectedPackage?.PrecioBase : '0' }
                                                </span>
                                                :
                                                <input type="number"
                                                    step=".01"
                                                    value={ precioBase } 
                                                    onChange={ (e) => setPrecioBase(e.target.value) }
                                                    className="form-control form-control-sm font-small text-right"
                                                    id="inputPrecioBase" name="precioBase" 
                                                    ref={ register }/>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1 col-2">{translate("dashboard.packages.loadpackages.formProvider.customsCharge")}<span className="font-xsmall">(B/)</span>:</td>
                                        <td className="ml-3 p-1 col-1">
                                            { props.optionSelected !== "ChangeInfo" ?
                                                <span className="font-bold text-right" >
                                                    { props.selectedPackage?.CargoAduana ? props.selectedPackage?.CargoAduana : '0' }
                                                </span>
                                                :
                                                <input type="number"
                                                    step=".01"
                                                    value={ cargoAduana } 
                                                    onChange={ (e) => setCargoAduana(e.target.value) }
                                                    className="form-control form-control-sm font-small text-right"  
                                                    id="inputCargoAduana" name="cargoAduana" 
                                                    ref={ register }/>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1 col-2">{translate("dashboard.packages.loadpackages.formProvider.othersCharges")} <span className="font-xsmall">(B/)</span>:</td>
                                        <td className="ml-3 p-1 col-1">
                                            { props.optionSelected !== "ChangeInfo" ?
                                                <span className="font-bold text-right" >
                                                    { props.selectedPackage?.OtrosCargos ? props.selectedPackage?.OtrosCargos : '0' }
                                                </span>
                                                :
                                                <input type="number"
                                                    step=".01"
                                                    value={ otrosCargos }
                                                    onChange={ (e) => setOtrosCargos(e.target.value) }
                                                    className="form-control form-control-sm font-small text-right"
                                                    id="inputOtrosCargos" name="otrosCargos" 
                                                    ref={ register }/>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1 col-2">ITBMS <span className="font-xsmall">(B/)</span>:</td>
                                        <td className="ml-3 p-1 col-1 font-bold text-right">
                                            { itbms }                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1 col-2">{translate("dashboard.packages.loadpackages.formProvider.costTotal")} <span className="font-xsmall">(B/)</span>:</td>
                                        <td className="ml-3 p-1 col-1 font-bold text-right">
                                            { precio }
                                        </td>
                                    </tr>
                                </>
                                :
                                <tr>
                                    <td className="p-1 col-2">{translate("global.totalAmount")}:</td>
                                    <td className="ml-3 p-1 col-1">
                                        { props.optionSelected !== "ChangeInfo" ?
                                            <span  className="font-bold">{ formatter.format(props.selectedPackage?.Precio ? props.selectedPackage?.Precio : 0) }</span>
                                            :
                                            <input type="number"
                                                className="form-control form-control-sm text-right font-small"
                                                step=".01"
                                                defaultValue={ props.selectedPackage?.Precio ? Number(props.selectedPackage?.Precio) : 0 } 
                                                name="precio" 
                                                ref={ register }/>
                                        }
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                {
                    props.isOpenModalChangeClient && 
                    <div>
                        { renderModalChangeClient }
                    </div>
                }
                
                { (props.optionSelected === "ChangePlatform" || props.optionSelected === "ChangeClient" || props.optionSelected === "ChangeInfo" || props.optionSelected === "ChangeDateExpiration") &&
                    <div className="row justify-content-center">
                        <button className="btn btn-sm btn-outline-fucsia m-2" type="reset" onClick={ () => cancelOption() }>{translate("global.cancel")}</button>
                        <button className="btn btn-sm btn-fucsia m-2" type="submit" >{translate("global.register")}</button>
                    </div>
                }
            </form>
}


export default PackageInfo;
