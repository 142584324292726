import React, { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form/dist/index.ie11";
import { IoPersonCircle } from 'react-icons/io5';
import {  FaRegBuilding, FaTimesCircle, FaUser} from 'react-icons/fa';
import { MdOutlineMailOutline, MdPhone, MdDateRange } from 'react-icons/md';
import { ImProfile, ImQrcode } from 'react-icons/im';
import * as FiIcons from 'react-icons/fi';
import * as RiIcons from 'react-icons/ri';
import QRCode from 'qrcode.react';
import PlatformSearchList from '../../../../components/PlatformSearchList';
import RoleBliSelect from '../../../../components/Selects/RoleBliSelect';
import TypePlansClientSelect from '../../../../components/Selects/TypePlansClientSelect';
import ModalContent from '../../../../components/ModalContent';
import FormManageBalance from '../FormManageBalance';
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { 
  formatter,
  verifyHideCountryItems, 
  translate, 
  getFormatDate,
  getNameRoleBli, 
  getNameTipoPlanCliente, 
  getColorStatusClient, 
  getIconStatusClients, 
  getNameStatusClients, 
  getNameGender, 
  copyToClipboard } from '../../../../utils/Common';


const ClientPersonalInfo = ({ 
    detailClient, 
    optionSelect, 
    handleSubmitUpdate, 
    handleIsBoxit,
    platformSelect, 
    setPlatformSelect, 
    renderBtnsUpdate,
    handleGetDetailsClient, 
    clientId,
    handleResetForm ,
    Accessible
  }) => {
  const [isAccessible, setIsAccessible] = useState(Accessible);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showAccessCode, setShowAccessCode] = useState(false);
  const [copied, setCopied] = useState(false);
  const personalInfoRef = useRef(null);
  const platformInfoRef = useRef(null);
  const manageBalanceInfoRef = useRef(null);
  const { register, handleSubmit, errors, setValue, reset } = useForm();
  
  const toggleShowAccessCode = () => setShowAccessCode(!showAccessCode);

  const handleCopyClipboard = (text) => {
    copyToClipboard(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  const DetailsInfoField = ({ children, detailClient }) => {
    let columnClass = "col-lg-4 col-md-6 col-sm-12";
    return <div className={`details-info ${columnClass}`}>{children}</div>;
  };

  return(
    <div className="details-section-information">
    <form onSubmit={handleSubmit(handleSubmitUpdate)}>
      {/* Info general Cliente - View */}
      <div className="details-section-info-group">
        <div className="box-card-general-dash w-100 position-relative">
        <div className="d-flex">
            <div className="details-profile-icon mr-3">
            <IoPersonCircle size={50} />
            </div>

            <div>
            <h6 className="text-capitalize font-bold">
                {detailClient?.Nombre + " " + detailClient?.Apellidos + 
                " (" + (detailClient?.IdTipoCliente === 1 ? "B" : "") + detailClient?.IdCliente + ")"}
            </h6>

            {detailClient?.Usuario && (
                <p className="mb-0">
                <FaUser className="mr-2" />
                <strong>{detailClient?.Usuario}</strong>
                </p>
            )}

            <div className="other-details-client mt-1 mb-2">
                {detailClient?.IdTipoCliente === 7 && detailClient?.IdRoleBli && (
                <div className="m-0 d-flex align-items-baseline" style={{ gap: "5px" }}>
                    <div className="m-0">
                    - {translate("global.role")} BLI:
                    </div>
                    <div>
                    {optionSelect === "EDIT_INFO_CLIENT" ? (
                        <RoleBliSelect
                        register={register}
                        itemDefault={detailClient?.IdRoleBli}
                        />
                    ) : (
                        <strong>
                        {getNameRoleBli(detailClient?.IdRoleBli)}
                        </strong>
                    )}
                    </div>
                </div>
                )}

                {detailClient?.IdTipoPlan && detailClient?.IdTipoCliente !== 7 && (
                <div className="m-0 d-flex align-items-baseline" style={{ gap: "5px" }}>
                    <div>
                    - {translate("global.type")} {translate("global.plan")}:
                    </div>
                    <div>
                    {optionSelect === "EDIT_INFO_CLIENT" ? (
                        <TypePlansClientSelect
                        register={register}
                        itemDefault={detailClient?.IdTipoPlan}
                        />
                    ) : (
                        <strong>
                        {getNameTipoPlanCliente(detailClient?.IdTipoPlan)}
                        </strong>
                    )}
                    </div>
                </div>
                )}
            </div>

            <small className="mt-2 mb-0">
            {detailClient?.FechaCreacion? (
              translate("global.createdThe") +
              " " +
              getFormatDate(detailClient?.FechaCreacion ? detailClient?.FechaCreacion : ""
              )
            ) : (
              ""
            )}
          </small>

            </div>
        </div>

        <div
            style={{
            position: "absolute",
            top: "-15px",
            right: "-10px",
            }}
        >
            <div className={"badge-status " + getColorStatusClient(detailClient?.IdEstatus)}>
            {translate("global.status")}:
            <span className='m-1'>
                {getIconStatusClients(detailClient?.IdEstatus)}
            </span>
            <span className="text-uppercase font-weight-bold">
                {getNameStatusClients(detailClient?.IdEstatus)}
            </span>
            </div>
        </div>
        </div>

          {/* Plataformas */}
          <div className="box-card-general-dash w-100" ref={platformInfoRef} >
            <div className="d-flex flex-row align-content-center" style={{ gap: "15px", height:"fit-content" }}>
              <div>
                <img
                  src="/../img/locker.png"
                  alt="locker.png"
                  style={{ height: "75px" }}
                />
              </div>
          {/* Plataforma Principal */}
              <div className="mr-5">
              {!verifyHideCountryItems(["us"]) ? ( 
                   <>
                    <small>{translate("global.platformPri")}</small>
                   </>
                  ): ( 
                    <small>{translate("global.platform")}</small>
                  )}
                {optionSelect !== "EDIT_PLATFORM_CLIENT" ? (
                  <>
                    <h6 className="text-capitalize">
                      {detailClient?.Plataforma}
                    </h6>
                {verifyHideCountryItems(["us"]) && ( 
                    <div className="badge-status">
                        <span className="mr-1">
                        <RiMoneyDollarCircleFill size={20} />
                        </span>
                        {translate("global.balance") + ": "}
                        <span className="text-uppercase font-weight-bold">
                        {detailClient?.SaldoCliente ? formatter.format(detailClient?.SaldoCliente) : "0.00"}
                        </span>
                    </div>
                    )}
                  </>
                ) : (
                  <>
                     <PlatformSearchList
                      register={register} 
                      errors={errors}
                      setPlatformSelect={setPlatformSelect}
                      platfSelect={platformSelect} 
                      valueId={"plataforma"} 
                      readOnly={false}
                      setValue={setValue}
                      idPlatform={detailClient?.IdPlataforma}
                      handleIsBoxit={handleIsBoxit}
                      />

                    {optionSelect === "EDIT_PLATFORM_CLIENT" && renderBtnsUpdate({
                      isDisabled: platformSelect ? false : true,
                    })}
                  </>
                )}
              </div>
           </div>

          </div>
        {/* Plataforma Secundaria */}
       {!verifyHideCountryItems(["us"]) && ( 
          <div className="box-card-general-dash w-100" ref={platformInfoRef} >
            <div className="d-flex flex-row align-content-center" style={{ gap: "15px", height:"fit-content" }}>
            
              <div>
                <img
                  src="/../img/locker.png"
                  alt="locker.png"
                  style={{ height: "75px" }}
                />
              </div>
        
            <div>
                {/* Verificar si 'NombrePlataformaSec' existe */}
                
                  <>
                     <small>{translate("global.platformSec")}</small>
                    {/* Si 'NombrePlataformaSec' existe, mostrar el nombre */}
                    {optionSelect !== "EDIT_PLATFORM_SEC_CLIENT" ? (
                      <h6 className="text-capitalize">
                        {detailClient?.NombrePlataformaSec}
                      </h6>
                    ) : (
                      <>
                        {/* Mostrar el componente de búsqueda de plataforma si estamos en la opción de edición */}
                        <PlatformSearchList
                          register={register}
                          errors={errors}
                          setPlatformSelect={setPlatformSelect}
                          platfSelect={platformSelect}
                          valueId={"plataformaSec"}
                          readOnly={false}
                          setValue={setValue}
                          idPlatform={detailClient?.IdPlataformaSec}
                          handleIsBoxit={handleIsBoxit}
                        />

                        {/* Renderizar los botones de actualización si 'optionSelect' es 'EDIT_PLATFORM_CLIENT' */}
                        {optionSelect === "EDIT_PLATFORM_SEC_CLIENT" && renderBtnsUpdate({
                          isDisabled: platformSelect ? false : true, // Deshabilitar si no hay selección de plataforma
                        })}
                      </>
                    )}
                  </>
          
              </div>
          
           </div>

          </div>
        )}
      </div>

      {/* Informacion Personal - View */}
      {
        optionSelect !== "MANAGEMENT_BALANCE" && (
      <div className="details-section-info-group" ref={personalInfoRef}>
        <div className="box-card-general-dash w-100">
          <h6>
            <ImProfile /> {translate("dashboard.userprofile.personal.title")}
          </h6>

          {optionSelect === "EDIT_INFO_CLIENT" && renderBtnsUpdate({isDisabled:false})}

        <div className="row">
            <DetailsInfoField>
              <div className="details-info-label">{translate("global.name")}</div>
              {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.Nombre}
                  name="name"
                  ref={register}
                />
              ) : (
                <div className="details-info-response">{detailClient?.Nombre}</div>
              )}
            </DetailsInfoField>

            <DetailsInfoField>
              <div className="details-info-label">{translate("global.lastNames")}</div>
              {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.Apellidos}
                  name="lastName"
                  ref={register}
                />
              ) : (
                <div className="details-info-response">{detailClient?.Apellidos}</div>
              )}
            </DetailsInfoField>
    
            <DetailsInfoField>
              <div className="details-info-label">
                <MdOutlineMailOutline className="mr-1" size={18} />
                {translate("global.email")}
              </div>
              {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.Email}
                  disabled
                  
                />
              ) : (
                <div className="details-info-response">{detailClient?.Email} </div>
              )}
            </DetailsInfoField>

            {detailClient?.Sexo && (<> 
            <DetailsInfoField>
                <div className="details-info-label">{translate("global.gender")}</div>
                {optionSelect === "EDIT_INFO_CLIENT" ? (
                  <select
                    className="form-control form-control-sm mt-2"
                    defaultValue={detailClient?.Sexo}
                    name="gender"
                    ref={register}
                  >
                    <option value="H">{translate("global.male")}</option>
                    <option value="M">{translate("global.female")}</option>
                  </select>
                ) : (
                  <div className="details-info-response">
                    {getNameGender(detailClient?.Sexo)}
                  </div>
                )}
              </DetailsInfoField>
            
            </> )}
                
            <DetailsInfoField>
              <div className="details-info-label">
                <MdPhone className="mr-1" size={18} />
                {translate("global.phone")}
              </div>
              {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.Telefono}
                  name="phone"
                  ref={register}
                />
              ) : (
                <div className="details-info-response">{detailClient?.Telefono} </div>
              )}
            </DetailsInfoField>
            
            
            {detailClient?.FechaNacimiento && ( <> 
              <DetailsInfoField>
                <div className="details-info-label">
                  <MdDateRange className="mr-1" size={18} />
                  {translate("dashboard.userprofile.personal.birthdate_label")}
                </div>
                {optionSelect === "EDIT_INFO_CLIENT" ? (
                  <input
                    type="date"
                    className="form-control form-control-sm mt-2"
                    defaultValue= {detailClient?.FechaNacimiento ? getFormatDate(detailClient.FechaNacimiento) : ""}
                    name="birthDate"
                    ref={register}
                  />
                ) : (
                  <div className="details-info-response">
                    {detailClient?.FechaNacimiento ? getFormatDate(detailClient.FechaNacimiento) : ""}
                  </div>
                )}
              </DetailsInfoField>
            
            </> )}
          
            {!verifyHideCountryItems(["us"]) && (
            <> 
          
            <DetailsInfoField>
              <div className="details-info-label">{translate("global.Country")}</div>
              {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.Pais}
                  name="pais"
                  ref={register}
                />
              ) : (
                <div className="details-info-response">{detailClient?.Pais}</div>
              )}
            </DetailsInfoField>

            <DetailsInfoField>
              <div className="details-info-label">{translate("global.StateLocation")}</div>
              {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.Estado}
                  name="estado"
                  ref={register}
                />
              ) : (
                <div className="details-info-response">{detailClient?.Estado}</div>
              )}
            </DetailsInfoField>

            <DetailsInfoField>
              <div className="details-info-label">{translate("global.City")}</div>
              {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.Ciudad}
                  name="ciudad"
                  ref={register}
                />
              ) : (
                <div className="details-info-response">{detailClient?.Ciudad}</div>
              )}
            </DetailsInfoField>

            <DetailsInfoField>
              <div className="details-info-label">{translate("global.Address1")}</div>
              {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.Address1}
                  name="address1"
                  ref={register}
                />
              ) : (
                <div className="details-info-response">{detailClient?.Address1}</div>
              )}
            </DetailsInfoField>
            
            <DetailsInfoField>
              <div className="details-info-label">{translate("global.Address2")}</div>
              {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.Address2}
                  name="address2"
                  ref={register}
                />
              ) : (
                <div className="details-info-response">{detailClient?.Address2}</div>
              )}
            </DetailsInfoField>

            <DetailsInfoField>
            <div className={`details-info-label d-flex align-items-center `}
             >
            <span className="mr-2">{translate("global.ExternalID")}</span> 
            <span 
                type="button"
                className={`input-group-prepend`}
                onClick={() => handleCopyClipboard(detailClient?.UserIdGL)}
              >
                <RiIcons.RiFileCopyFill size={14} />
              </span>
              </div>
            {optionSelect === "EDIT_INFO_CLIENT" ? (
                <input
                  type="text"
                  className="form-control form-control-sm mt-2"
                  defaultValue={detailClient?.UserIdGL}
                  name="externalId"
                  ref={register}
                />
              ) : (
                <> 
              <div className={`details-info-response ${optionSelect === "EDIT_INFO_CLIENT" ? "mt-2" : ""}`}>{detailClient?.UserIdGL}</div>
              </>
                )} 
            {copied && <span className="text-success ml-2">{translate("global.copied")}</span>}
            
           </DetailsInfoField>

            <DetailsInfoField>
                <>
                  <div className="details-info-label">{translate("global.AccessCode")}</div>
                  <div className="input-group">
                  {optionSelect === "EDIT_INFO_CLIENT" ? (
               <> 
                    <input
                     type={showAccessCode ? "text" : "password"}
                      className={`form-control form-control-sm ${optionSelect === "EDIT_INFO_CLIENT" ? "mt-2" : ""}`}
                      defaultValue={detailClient?.AccessCode}
                      name="accessCode"
                      ref={register}
                    />
                      <div className="input-group-prepend">
                      <span
                        type="button"
                        className={`input-group-text span-eye-pwd ${optionSelect === "EDIT_INFO_CLIENT" ? "mt-2" : ""}`}
                        onClick={toggleShowAccessCode}
                      >
                        {showAccessCode ? <FiIcons.FiEyeOff /> : <FiIcons.FiEye />}
                      </span>
                    </div>
                     </>
                  ) : (
                    <> 
                     <input
                      type={showAccessCode ? "text" : "password"}
                      className={`form-control form-control-sm ${optionSelect === "EDIT_INFO_CLIENT" ? "mt-2" : ""}`}
                      defaultValue={detailClient?.AccessCode}
                      disabled
                    />
                    <div className="input-group-prepend">
                      <span
                        type="button"
                        className={`input-group-text span-eye-pwd ${optionSelect === "EDIT_INFO_CLIENT" ? "mt-2" : ""}`}
                        onClick={toggleShowAccessCode}
                      >
                        {showAccessCode ? <FiIcons.FiEyeOff /> : <FiIcons.FiEye />}
                      </span>
                    </div>
                    </>
                    )}
                  </div>
                </>
             
            </DetailsInfoField>
          
            <DetailsInfoField>
                <div className="details-info-label">{translate("global.HashKey")}</div>
                <div className="input-group">
                {optionSelect === "EDIT_INFO_CLIENT" ? (
                
                    <input
                    type="text"
                    className={`form-control form-control-sm ${optionSelect === "EDIT_INFO_CLIENT" ? "mt-2" : ""}`}
                    defaultValue={detailClient?.HashKey}
                    name="hashKey"
                    ref={register}
                    />
                ) : (
                    <>
                    <input
                    type="password"
                    className={`form-control form-control-sm ${optionSelect === "EDIT_INFO_CLIENT" ? "mt-2" : ""}`}
                    defaultValue={detailClient?.HashKey}
                    disabled
                    />
                    <div className="input-group-prepend">
                    <span
                        type="button"
                        className={`input-group-text span-eye-pwd ${optionSelect === "EDIT_INFO_CLIENT" ? "mt-2" : ""}`}
                        onClick={ () => setIsOpenModal(true)}
                    >
                        <ImQrcode />
                    </span>
                    </div> 

                    </>
                    )}

                </div>  
            </DetailsInfoField>

                <ModalContent
                        title={translate("global.codeQR")}
                        body={<QRCode value={detailClient?.HashKey} />}
                        footer={<button className="btn btn-sm btn-fucsia" onClick={ () => setIsOpenModal(false)}
                        >{translate("global.close")}</button>}
                        isOpenModal={isOpenModal}
                        onHide={() => setIsOpenModal(false)}
                        />

            <DetailsInfoField>
            <div className="details-info-label">{translate("global.Accesibility")}</div>
            <div className="custom-control custom-switch mb-2 mt-2">
                {optionSelect === "EDIT_INFO_CLIENT" ? (
                <>
                    <input
                    className="custom-control-input form-control"
                    type="checkbox"
                    id="inputAcc"
                    name="acc"
                    ref={register} 
                    onChange={(e) => setIsAccessible(e.target.checked)} // Actualiza el estado local al cambiar
                    checked={isAccessible} 
                    />
                    <label className="custom-control-label mb-1" htmlFor="inputAcc">
                    {isAccessible ? (
                        <span className="text-success text-bold">{translate("global.yes")}</span>
                    ) : (
                        <span className="text-danger text-bold">{translate("global.no")}</span>
                    )}
                    </label>
                </>
                ) : (
                <>
                    <input
                    className="custom-control-input form-control"
                    type="checkbox"
                    id="inputAcc"
                    checked={detailClient?.Acc} 
                    disabled 
                    />
                    <label className="custom-control-label mb-1" htmlFor="inputAcc">
                    {detailClient?.Acc === true ? (
                        <span className="text-success text-bold">{translate("global.yes")}</span>
                    ) : (
                        <span className="text-danger text-bold">{translate("global.no")}</span>
                    )}
                    </label>
                </>
                )}
            </div>
            </DetailsInfoField>
            </>
            )}
            
                  
      </div>
       {/* Info BLI Empresa - View */}
       {verifyHideCountryItems(["us"]) && (
         <>
          {
          detailClient?.IdTipoCliente === 7 && (
              <div className="details-section-info-group">
                  <div className="box-card-general-dash w-100">
                    <h6>
                      <FaRegBuilding /> {translate("global.companyInfo")}
                    </h6>
                    <div className="row">
                      <div className="details-info col-lg-4 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.name")}
                        </div>
                        
                          <div className="details-info-response">
                            {detailClient?.CompanyLastName} {detailClient?.CompanyName}
                          </div>
                      </div>
  
                      <div className="details-info col-lg-4 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.hoursExpire")}
                        </div>
                        
                          <div className="details-info-response">
                            {detailClient?.HoursXExpire} h
                          </div>
                      </div>
  
                      <div className="details-info col-lg-4 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.dateCreated")}
                        </div>
                        {detailClient?.CompanyDateCreate && ( 
                          <div className="details-info-response">
                            {detailClient?.CompanyDateCreate ? getFormatDate(detailClient?.CompanyDateCreate) : ".."}
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
          )
          }
        </>
        )}
      </div>
    </div>
        )
      }
    </form>

    <div ref={manageBalanceInfoRef}>
    {optionSelect === "MANAGEMENT_BALANCE" &&(
          <FormManageBalance clientId={clientId} resetForm={handleResetForm} currentBalance={detailClient?.SaldoCliente} handleGetDataClient={handleGetDetailsClient}/>
    ) }
    </div>
  </div>
  );

};

export default ClientPersonalInfo;