import { useContext } from "react";
import { usePlatforms } from "../../../../../hooks/usePlatforms";
import ModalContext from "../../../../../contexts/modal";
import { translate } from "../../../../../utils/Common";


const LockerCard = ({ pachageInfo }) => {
  const modalContext = useContext(ModalContext)

  const { openLocker } = usePlatforms();

  const handleConfirm = (data) => {
    modalContext.confirm(
      translate("global.alert.attention"),
      translate("global.confirmOpenLocker"),
      [
        <button
          type="button"
          key={1}
          className="btn btn-sm btn-fucsia"
          onClick={() => handleOpenLocker(data)}
        >{translate("global.accept")}</button>,
        <button
          type="reset"
          key={2}
          className="btn btn-sm btn-outline-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.cancel")}
        </button>,
      ],
      true
    );
  };

  const handleOpenLocker = async () => {
    try {
      let dataToSend = {}
      dataToSend.idUsuario = pachageInfo.IdUsuario;
      dataToSend.idPaquete = pachageInfo.IdPaquete;
      dataToSend.idPlataforma = pachageInfo.IdPlataforma;
      const response = await openLocker(dataToSend);
      if (response.message === "OPENED_SUCCESSFULLY") {
        modalContext.confirm('¡Listo!',
          <span>{translate("dashboard.platforms.support.openLockerCard.OPENED_SUCCESSFULLY")}</span>,
          <button type="button" className="btn btn-sm btn-outline-fucsia" onClick={() => modalContext.setIsOpenModal(false)}>{translate("global.accept")}</button>,
          true);
      }
    } catch (error) {
      modalContext.confirm(translate("global.alerts.ops"),
        <span>{translate("dashboard.platforms.support.openLockerCard.ERROR_OPENING_LOCKER")}</span>,
        <button type="button" className="btn btn-sm btn-fucsia" onClick={() => modalContext.setIsOpenModal(false)}>{translate("global.accept")}</button>,
        true);
      console.log(error);
    }
  }

  const handlerEstatus = value => {
    switch (value) {
      case 1:
        return 'text-warning';
      case 2:
        return 'text-info';
      case 3:
        return 'text-warning';
      case 4:
        return 'text-success';
      case 5:
        return 'text-danger';
      case 6:
        return 'text-danger'
      default:
        break;
    }
  }

  return (
    <div className="row mx-2">
      <div className="col-12 col-md-6 p-2">
        <div className="dashboardCard text-left m-0 h-100">
          <h5 className="card-title">{translate("dashboard.platforms.support.openLockerCard.title")}</h5>
          <div className="row mt-6">
            <div className="col-5">
              <div className="form-group mb-2">
                <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                  {translate("global.numPackage")}
                </label>
                <input
                  type="text"
                  disabled
                  className=
                  "form-control text-bold  form-control-md font-small"
                  id="nombre"
                  name="packageNumber"
                  defaultValue={pachageInfo.Nro_Id ? pachageInfo.Nro_Id : ''}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group mb-2">
                <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                  {translate("global.key")}
                </label>
                <input
                  type="text"
                  disabled
                  className=
                  "form-control form-control-md text-bold font-small"
                  id="nombre"
                  name="password"
                  defaultValue={pachageInfo.Clave ? pachageInfo.Clave : ''}

                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-2">
                <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                  {translate("global.state")}
                </label>
                <input type="text" value={pachageInfo.Estatus ? pachageInfo.Estatus : ""} readOnly className={"form-control-plaintext form-control-sm font-small font-bold " + (handlerEstatus(pachageInfo.IdEstatus))} id="estatus" />
              </div>
            </div>
            <div className="col-8">
              <div className="form-group mb-2">
                <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                  {translate("global.client")}
                </label>
                <input
                  type="text"
                  disabled
                  className=
                  "form-control form-control-md text-bold font-small"
                  id="nombre"
                  name="clientId"
                  defaultValue={pachageInfo.Cliente ? pachageInfo.Cliente : ''}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-2">
                <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                  Id {translate("global.client")}
                </label>
                <input
                  type="text"
                  disabled
                  className=
                  "form-control form-control-md text-bold font-small"
                  id="nombre"
                  name="nombre"
                  defaultValue={pachageInfo.IdCliente ? pachageInfo.IdCliente : ''}

                />
              </div>
            </div>

            {
              pachageInfo.PackageIdGL && (
                <div className="col-6">
                  <div className="form-group mb-2">
                    <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                      {translate("global.reservationId")}
                    </label>
                    <input
                      type="text"
                      disabled
                      className=
                      "form-control form-control-md text-bold font-small"
                      defaultValue={pachageInfo.PackageIdGL ? pachageInfo.PackageIdGL : ''}

                    />
                  </div>
                </div>
              )
            }

            {
              pachageInfo.IdReserva && (
                <div className="col-6">
                  <div className="form-group mb-2">
                    <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                      {translate("global.locationId")}
                    </label>
                    <input
                      type="text"
                      disabled
                      className=
                      "form-control form-control-md text-bold font-small"
                      defaultValue={pachageInfo.DescPlataforma ? pachageInfo.DescPlataforma : ''}
                    />
                  </div>
                </div>
              )
            }

          </div>

        </div>
      </div>
      <div className="col-12 col-md-6 p-2">
        <div className="dashboardCard text-left m-0 h-100">
          <h5 className="card-title">{translate("dashboard.platforms.support.openLockerCard.dataLocker")}</h5>

          <div className="row">
            <div className="col-6">
              <div className="form-group mb-2">
                <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                  {translate("global.platform")}
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control form-control-md text-bold font-small"
                  id="nombre"
                  name="nombre"
                  defaultValue={pachageInfo.Plataforma ? pachageInfo.Plataforma : ''}

                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group mb-2">
                <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                  {translate("global.platform")} Id
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control form-control-md text-bold font-small"
                  id="nombre"
                  name="nombre"
                  defaultValue={pachageInfo.IdPlataforma ? pachageInfo.IdPlataforma : ''}
                />
              </div>
            </div>

            { pachageInfo.DescPlataforma &&
              <div className="col-6">
                <div className="form-group mb-2">
                  <label className="mb-1 mr-1" htmlFor="inputLocationId">
                    {translate("dashboard.platforms.support.openLockerCard.locationId")}
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control form-control-md text-bold font-small"
                    id="locationId"
                    name="locationId"
                    defaultValue={ pachageInfo.DescPlataforma }
                  />
                </div>
              </div>
            }

            <div className="col-6">
              <div className="form-group mb-2">
                <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                  {translate("global.numDoor")}
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control form-control-md text-bold font-small"
                  id="nombre"
                  name="nombre"
                  defaultValue={pachageInfo.Identificacion ? pachageInfo.Identificacion : translate("global.notAssigned")}
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group mb-2">
                <label className="mb-1 mr-1" htmlFor="inputNumeroPaquete">
                  {translate("global.door")} Id
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control form-control-md text-bold font-small"
                  id="nombre"
                  name="nombre"
                  defaultValue={pachageInfo.IdPlataformaDet ? pachageInfo.IdPlataformaDet : 'Sin asignar '}
                />
              </div>
            </div>

            <div className="col-12  d-flex justify-content-center mt-3">
              <div className="form-group col-md-7 d-flex justify-content-center px-0  mb-0">
                <button disabled={!pachageInfo.IdPlataformaDet} onClick={handleConfirm} className="btn btn-sm btn-blue">{translate("dashboard.platforms.support.openLockerCard.openDoor")}</button>
              </div>
            </div>

            <div className="col-12  d-flex justify-content-center mt-3">
              <div className="form-group d-flex justify-content-center px-0  mb-0">
                {!pachageInfo.IdPlataformaDet &&
                  <label className="mb-1"><span className="text-danger">{translate("dashboard.platforms.support.openLockerCard.asignDoor")} </span></label>
                }                  </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default LockerCard;