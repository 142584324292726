import { setValueSearchFilter, getValueSearchFilter, translate } from "../utils/Common";
import { BsList, BsGrid3X3GapFill } from 'react-icons/bs';

const SearchBoxItems = ({ itemsArray, itemsFound, setItemsShowing, setItemsFiltered, setView, view, itemName, filterType }) => {

    const filterByPackage = (value, item) => {
        const upperCaseValue = value.toUpperCase();
        return (
            item.Nro_Id?.toUpperCase().includes(upperCaseValue) ||
            item.NroIdentificacion?.toUpperCase().includes(upperCaseValue) ||
            item.NumeroTracking?.toUpperCase().includes(upperCaseValue) ||
            item.Cliente?.toUpperCase().includes(upperCaseValue) ||
            item.Direccion?.toUpperCase().includes(upperCaseValue) ||
            item.Email?.toUpperCase().includes(upperCaseValue) ||
            item.zipCode?.toUpperCase().includes(upperCaseValue) ||
            item.NumeroIdentificacion?.toUpperCase().includes(upperCaseValue) ||
            item.Plataforma?.toUpperCase().includes(upperCaseValue) 
        );
    };

    const filterByPlatform = (value, item) => {
        const upperCaseValue = value.toUpperCase();
        return (
            item.locationName?.toUpperCase().includes(upperCaseValue) ||
            item.locationId?.toUpperCase().includes(upperCaseValue) ||
            item.zipCode?.toUpperCase().includes(upperCaseValue) ||
            item.Plataforma?.toUpperCase().includes(upperCaseValue)
        );
    };

    const filterByAlerts = (value, item) => {
        const upperCaseValue = value.toUpperCase();
        return (
            item.NumeroTracking?.toUpperCase().includes(upperCaseValue) ||
            item.Email?.toUpperCase().includes(upperCaseValue) ||
            item.NombreCompleto?.toUpperCase().includes(upperCaseValue) ||
            String(item.IdCliente)?.includes(upperCaseValue)
        );
    };
    

    const handleSearchBoxFilter = (value) => {
        setValueSearchFilter(value);
        if (itemsArray?.length) {
            
            let filteredItems;
            switch(filterType) {
                case 'package':
                    filteredItems = itemsArray.filter(item => filterByPackage(value, item));
                    break;
                case 'platform':
                    filteredItems = itemsArray.filter(item => filterByPlatform(value, item));
                    break;
                case 'alerts':
                    filteredItems = itemsArray.filter(item => filterByAlerts(value, item));
                    break;
                default:
                    filteredItems = itemsArray;
            }
            setItemsFiltered(filteredItems);
        }
    };

    return (
        <div className="row mx-0">
            <div className="col-5 col-sm-6 col-md-4 d-inline-flex align-items-center mb-2 pr-1 pl-0">
                <span className="font-small mr-2">{translate("global.showing")}</span>
                <select onChange={ (e) => setItemsShowing(Number(e.target.value)) } className="form-control form-control-sm font-small">
                    <option value={ itemsFound?.length >= 20 ? 20 : itemsFound?.length }>{ itemsFound?.length >= 20 ? 20 : itemsFound?.length }</option>
                    { itemsFound?.length > 20 && <option value={ (itemsFound?.length > 20 && itemsFound?.length <= 40) ? itemsFound?.length : 40 }>{ (itemsFound?.length > 20 && itemsFound?.length <= 40) ? itemsFound?.length : 40 }</option> }
                    { itemsFound?.length > 40 && <option value={ (itemsFound?.length > 40 && itemsFound?.length <= 60) ? itemsFound?.length : 60 }>{ (itemsFound?.length > 40 && itemsFound?.length <= 60) ? itemsFound?.length : 60 }</option> }
                </select>
            </div>

            <div className="col-7 col-sm-6 col-md-4 d-inline-flex align-items-center mb-2 pr-0 pl-1">
                <span className="font-small mr-2">{translate("global.filter")}:</span>
                <input type="text" className="form-control form-control-sm font-small" 
                    onChange={ (e) => handleSearchBoxFilter(e.target.value ? e.target.value : '') }
                    defaultValue={ getValueSearchFilter() ? getValueSearchFilter() : '' }/>
            </div>
            
            { itemName === (translate("global.deliveries")) &&
                <div className="col-12 col-md-4 d-inline-flex justify-content-end px-0 mb-2">
                    <span className="font-small p-1">{translate("global.toSeenIn")}:</span>
                    <div className="btn-group ml-2">
                        <button className={`btn btn-sm btn-${view === 'grid' ? '' : 'outline-'}blue`} type="button" onClick={() => setView('grid')}><BsGrid3X3GapFill /></button>
                        <button className={`btn btn-sm btn-${view !== 'grid' ? '' : 'outline-'}blue`} type="button" onClick={() => setView('list')}><BsList /></button>
                    </div>
                </div>
            }
        </div>
    )
}

export default SearchBoxItems;