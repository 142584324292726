import React from 'react';
import { FaChevronCircleDown, FaTimesCircle } from 'react-icons/fa';
import { CiEdit } from 'react-icons/ci';
import { GiPlatform } from 'react-icons/gi';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import {translate, verifyHideCountryItems} from '../../../../utils/Common';
import { verifyAccessViewPermissions } from "../../../../config/permissions";
import { FaCircleCheck } from "react-icons/fa6";
import {
    FaRegBuilding,
    
    FaUser,
    FaUserFriends,
  } from "react-icons/fa";

const DetailsSectionOptions = ({
  isMobile,
  isMenuOpen,
  setIsMenuOpen,
  handleEditOptionClick,
  detailClient
 
}) => {
  return ( <> 
 
    <div className="details-section-option">
      <div className="details-section-option-item active">
        {translate("global.generalData")}
      </div>
     
      {isMobile && (
        <div
          id="details-options-viewactions"
          className="details-section-option-item"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div>
            <span>{translate("global.chooseOption")}</span>
          </div>
          <FaChevronCircleDown size={15} />
        </div>
      )}

      {(!isMobile || isMenuOpen) && (
        <>
       
        
          <div
            className="details-section-option-item"
            onClick={() => handleEditOptionClick("EDIT_INFO_CLIENT")}
          >
            <CiEdit size={20} /> {translate("global.editData")}
          </div>

          <div
            className="details-section-option-item"
            onClick={() => handleEditOptionClick("EDIT_PLATFORM_CLIENT")}
          >
            <GiPlatform size={20} />
            {translate("global.editPlatform")}
          </div>
         {!verifyHideCountryItems(["us"])  &&( 
          <div
            className="details-section-option-item"
            onClick={() => handleEditOptionClick("EDIT_PLATFORM_SEC_CLIENT")}
          >
            <GiPlatform size={20} />
            {translate("global.edit")} {translate("global.platformSec")}
          </div>
          )}
          {(detailClient?.IdEstatus === 2 || detailClient?.IdEstatus === 3) && (
            <div
              className="details-section-option-item"
              onClick={() => handleEditOptionClick("ACTIVATE_CLIENT")}
            >
              <FaCircleCheck size={16} />
              {translate("global.activateClient")}
            </div>
          )}

          {(detailClient?.IdEstatus === 1 || detailClient?.IdEstatus === 3) && (
            <div
              className="details-section-option-item"
              onClick={() => handleEditOptionClick("DESACTIVATE_CLIENT")}
            >
              <FaTimesCircle size={16} />
              {translate("global.desactivateClient")}
            </div>
          )}

          {verifyAccessViewPermissions(["update_management_balance"]) && verifyHideCountryItems(["br"]) && (
            <div
              className="details-section-option-item"
              onClick={() => handleEditOptionClick("MANAGEMENT_BALANCE")}
            >
              <RiMoneyDollarCircleFill size={18} />
              {translate("global.balanceManagement")}
            </div>
          )}
        
        </> )}
         
    
    </div>
    
    </>);
};

export default DetailsSectionOptions;