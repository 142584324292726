import {
  translate,
  verifyHideCountryItems,
} from "../../../utils/Common";

import {
  FaRegBuilding,
  FaTimesCircle,
  FaUser,
  FaUserFriends,
} from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import { useParams } from "react-router-dom";
import { useClient } from "../../../hooks/useClient";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import ModalContext from "../../../contexts/modal";
import "./index.css";
import ClientPersonalInfo from "./DetailClientsComponents/ClientPersonalInfo";
import DetailsSectionOptions from "./DetailClientsComponents/DetailsSectionOptions";

const DetailsClients = () => {
  const { clientId } = useParams();
  const { getDetailsClient, updateDetailsClient } = useClient();
  const [detailClient, setDetailClient] = useState(null);
  const [optionSelect, setOptionSelect] = useState("");
  const [platformSelect, setPlatformSelect] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [idManageBalance, setIdManageBalance] = useState(null)
  const [copied, setCopied] = useState(false);
  const modalContext= useContext(ModalContext);
  // para saber qué boxit se seleccionó
  const [isBoxit, setIsBoxit] = useState(false);
  const handleIsBoxit = useCallback((value) => setIsBoxit(value), []);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showAccessCode, setShowAccessCode] = useState(false);
  const personalInfoRef = useRef(null);
  const platformInfoRef = useRef(null);
  const manageBalanceInfoRef = useRef(null);
  const { register, handleSubmit, errors, setValue, reset } = useForm();
  const [isAccessible, setIsAccessible] = useState(null);

  const handleGetDetailsClient = async (data) => {
    const abortController = new AbortController();
    const response = await getDetailsClient(abortController.signal, data);
    setDetailClient(response);

  };


 
  const handleUpdateClient = async (data) => {
    const formattedData = formatClientData(data); // Llama a la función de formato
  
    const abortController = new AbortController();
    const response = await updateDetailsClient(abortController.signal, {
      clientId: clientId,
      typeUpdate: optionSelect,
      plataforma: detailClient?.IdPlataforma,
      plataformaSec: detailClient?.IdPlataformaSec,
      ...formattedData
    });
    

    if (response) {
      if (response.code === 200) {
        modalContext.confirm(
          translate("global.alert.ready"),
          <span>{translate("dashboard.updateClient.success_message")}</span>,
          [
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.OK")}
            </button>,
          ],
          true
        );
      } else {
        modalContext.confirm(
          translate("global.alert.attention"),
          <span>{translate("dashboard.platforms.create.alert.error")}</span>,
          [
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.OK")}
            </button>,
          ],
          true
        );
      }
    }

    handleGetDetailsClient({
      clientId: clientId,
    });

    
    setOptionSelect("");
  };
  

  useEffect(() => {
    if (clientId) {
      handleGetDetailsClient({
        clientId: clientId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -------------------------

  const handleResetForm = () => {
    setPlatformSelect(null);
    reset()
    setOptionSelect("");
    setIsAccessible(detailClient?.Acc);
  }

  const renderBtnsUpdate = ({isDisabled}) => (
    <div className="d-flex mt-3">
      <button className="btn btn-fucsia btn-sm" disabled={isDisabled} type="submit">
        <IoIosSave size={18} className="mr-1" /> {translate("global.save")}
      </button>
      <button
        className="btn btn-outline-fucsia btn-sm ml-2"
        onClick={() => handleResetForm() }
        type="button"
      >
        {translate("global.cancel")}
      </button>
    </div>
  );

  const handleEditOptionClick = (option) => {
    setOptionSelect(option);

    if (isMobile) {
      setIsMenuOpen(!isMenuOpen);
    }
  

    switch (option) {
      case "EDIT_INFO_CLIENT":
        if (personalInfoRef.current) {
          personalInfoRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;

      case "EDIT_PLATFORM_CLIENT":
        if (platformInfoRef.current) {
          platformInfoRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;

        case "EDIT_PLATFORM_SEC_CLIENT":
        if (platformInfoRef.current) {
          platformInfoRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;

      case "DESACTIVATE_CLIENT":
        modalContext.confirm(
          translate("global.alert.attention"),
          <span>
            {translate("dashboard.clients.desactivate.alert.message")}
          </span>,
          [
            <button
              key={1}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.cancel")}
            </button>,
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => {
                handleSubmitUpdate({
                  idEstatus: 2,
                  typeUpdate: "EDIT_STATUS_CLIENT"
                });
              }}
            >
              {translate("global.accept")}
            </button>,
          ],
          true
        );

        break;

      case "ACTIVATE_CLIENT":
        modalContext.confirm(
          translate("global.alert.attention"),
          <span>{translate("dashboard.clients.activate.alert.message")}</span>,
          [
            <button
              key={1}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.cancel")}
            </button>,
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => {
                handleSubmitUpdate({
                  idEstatus: 1,
                  typeUpdate: "EDIT_STATUS_CLIENT"
                });
              }}
            >
              {translate("global.accept")}
            </button>,
          ],
          true
        );
        break;

      case "MANAGEMENT_BALANCE":
        if (manageBalanceInfoRef.current) {
          manageBalanceInfoRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      default:
        break;
    }
  };

  const handleSubmitUpdate = (data) => {
    handleUpdateClient(data);
  };

  const formatClientData = (data) => {
    let accValue = null; // Valor por defecto si no se recibe `acc` explícitamente
  
    if (Array.isArray(data.acc)) {
      accValue = data.acc.length > 0; // Si es un array y tiene longitud, está marcado
    } else if (typeof data.acc === "boolean") {
      accValue = data.acc; // Si es booleano, úsalo directamente
    }
    return {
      ...data,
      acc: accValue, // Reemplaza o añade el valor de `acc`
    };
  };
  
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
      const handleResize = () => window.innerWidth < 1040 ? setIsMobile(true) : setIsMobile(false);

      window.addEventListener('resize', handleResize);

      if (window.innerWidth < 1040) setIsMobile(true);
      
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (detailClient) {
      setIsAccessible(detailClient?.Acc);
    }
  }, [detailClient]);


  return (
    <>
     {/* Header */}
     <div
        style={{
          position: "sticky",
          top: "0",
          backdropFilter: "blur(8px)",
          zIndex: 15,
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          padding: "15px 25px 0",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="text-left d-flex" style={{ alignContent: "center" }}>
            <a href="/dashboard/clients">
              <h5 className="link-hover-effect">
                <FaUserFriends size={18} className="mr-2" />
                {translate("global.clients")}
              </h5>
            </a>

          {
            detailClient && (
              <span className="ml-1 mt-1">
              {" "}
              {">"}{" "}
              {detailClient?.Nombre + " " + detailClient?.Apellidos +" (" + (detailClient?.IdTipoCliente === 1 ? "B" : "")+
                detailClient?.IdCliente +
                ")"}
            </span>
            )
          }
          </div>
        </div>
     
      </div>
      
        { detailClient ? (
          <>
        {!verifyHideCountryItems(["us"]) && (  
          <div className="details-section-option-item active">
          {translate("global.generalData")}
        </div> )}
        
          <div
              style={{
                padding: " 0 15px ",
                marginBottom:"50px",
                background: "#fcfcfc",
              }}
              className="h-100 w-100"
              >

              <div className="box-card-general mt-3">
                <div className="details-section-general">
              {/* --- Options Select ---*/}
              {verifyHideCountryItems(["us"]) && ( 
                <>
                <DetailsSectionOptions 
                        isMobile={isMobile}
                        isMenuOpen={isMenuOpen}
                        setIsMenuOpen={setIsMenuOpen}
                        handleEditOptionClick={handleEditOptionClick}
                        detailClient={detailClient}

                  />
              </>)}

                {/* Info general Cliente - View */}
                  <ClientPersonalInfo 
                      detailClient={detailClient} 
                      optionSelect={optionSelect} 
                      register={register} 
                      errors={errors} 
                      setValue={setValue} 
                      handleSubmitUpdate={handleSubmitUpdate} 
                      handleIsBoxit={handleIsBoxit}
                      platformSelect={platformSelect}
                      setPlatformSelect={setPlatformSelect}
                      renderBtnsUpdate={renderBtnsUpdate}
                      handleGetDetailsClient={handleGetDetailsClient}
                      clientId={clientId}
                      handleResetForm={handleResetForm}
                      Accessible={isAccessible}
                  />
                </div>
              </div>

            </div>
         </>
        ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="spinner-border text-fucsia" role="status" style={{color:"#424996"}}>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        )}
    </>
   
  );
};

export default DetailsClients;
