import React, { useContext, useEffect, useState } from "react";
import MapContainerSearch from "../../../../components/MapContainerSearch";
import "./index.css";
import AuthContext from "../../../../contexts/auth";
import { usePlatforms } from "../../../../hooks/usePlatforms";
import { translate } from "../../../../utils/Common";

const LocatePlatforms = () => {
  const userContext = useContext(AuthContext);
  const { getPlatforms } = usePlatforms();

  const [platformsAvailable, setPlatformsAvailable] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar si los datos se están cargando

  useEffect(() => {
    const abortController = new AbortController();

    const handlePlatforms = async () => {
      try {
        const platforms = await getPlatforms(userContext.user?.IdCliente, abortController.signal);
        setPlatformsAvailable(platforms);
      } catch (error) {
        console.error("Error al cargar las plataformas:", error);
      } finally {
        setLoading(false); // Cuando se obtienen los datos, cambiamos el estado de carga a false
      }
    };

    handlePlatforms();

    return () => abortController.abort();
  }, [getPlatforms, userContext.user?.IdCliente]);

  return (
    <div>
      <div className="content-location-map">
        {loading ? (
          <div className="loading-screen">
            <div className="step-indicator">
              {[0, 1, 2, 3].map((step, index) => (
                <div
                  key={index}
                  className={`step ${loading && index === 3 ? "active" : ""}`}
                />
              ))}
            </div>
            <p>{translate("global.loading")}</p>
          </div>
        ) : (
          platformsAvailable.length > 0 && (
            <>
            <MapContainerSearch locations={platformsAvailable} zoomLevel={13} />
            </> )
        )}
      </div>
    </div>
  );
};

export default LocatePlatforms;
