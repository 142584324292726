import React, { useEffect, useRef, useState } from "react";
import { FaCaretLeft } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { firstDayOfMonth, formatDate, todayDate, translate, verifyHideCountryItems } from "../../../../utils/Common";
import { FaDoorOpen } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import { IoCheckmarkCircle } from "react-icons/io5";
import { TiDelete } from "react-icons/ti";
import { LuAlertCircle } from "react-icons/lu";
import { FaHistory } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { LuBox } from "react-icons/lu";
import { MdOutlineAccessTime } from "react-icons/md";
import * as RiIcons from "react-icons/ri";

import { usePlatforms } from "../../../../hooks/usePlatforms";
import Spinner from "../../../../components/Spinner";
import "./index.css";
import { useForm } from "react-hook-form";
import LockerDoor from "./LockerDoor";

const SmartLocker = ({ idPlatform, estatusPlatform, setListMovements, handleFocusMovements, setFilterMovementSelect, actionsPerms }) => {
  const [doors, setDoors] = useState(null);
  const [column1, setColumn1] = useState([]);
  const [column2, setColumn2] = useState([]);
  // const [column1Sizes, setColumn1Sizes] = useState({});
  // const [column2Sizes, setColumn2Sizes] = useState({});
  const [moduleShowed, setModuleShowed] = useState(0);
  const [modules, setModules] = useState([]);
  const [selectedDoor, setSelectedDoor] = useState([]);
  const [loadingDoors, setLoadingDoors] = useState({});
  const [btnActionIsActive, setBtnActionIsActive] = useState(false);
  const [actionsInProgress, setActionsInProgress] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [dateInputIsActive, setDateInputIsActive] = useState(false);
  const [defaultFromDate, setDefaultFromDate] = useState("");
  const [defaultToDate, setDefaultToDate] = useState("");
  const [messageMovements, setMessageMovements] = useState("");
  const [actionSelect, setActionSelect] = useState(null);
  const [activeSearch, setActiveSearch] = useState(false);
  const [showPackageInfo, setshowPackageInfo] = useState(null);
  // Referencia para almacenar el módulo mostrado
  const moduleShowedRef = useRef(0);

  const { actionInPlatform, getDoorsByPlatform } = usePlatforms();
  const { register, handleSubmit, errors } = useForm();

  const onSubmitFilterMovement = () => {
    setMessageMovements("");
    handleOptionSelection("movements");
  };

  const modalCustome = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 8,
      }}
    >
      {selectedDoor.map((id) => {
        let statusMessage = "";
        let numberDoor =
          doors
            .filter((objeto) => objeto.boxitDoorId === id)
            .map((obj) => obj.doorNumber)[0] || "-";

        switch (loadingDoors[id]) {
          case 0:
            statusMessage = <Spinner type={1} size={20} />;
            break;
          case 1:
            statusMessage = (
              <IoCheckmarkCircle size={20} color="rgb(74 222 128)" />
            );
            break;
          case 2:
            statusMessage = <TiDelete size={20} color="red" />;
            break;
          default:
            break;
        }

        return (
          <div key={id} style={styles.doorSelected}>
            <div>
              {translate("global.door")}: {numberDoor}{" "}
              <span style={{ color: "white" }}>{statusMessage}</span>
            </div>
          </div>
        );
      })}

      {dateInputIsActive && (
        <>
          <form>
            <div
              className="d-flex justify-align-center align-items-center"
              style={{ flexDirection: "row", flexWrap: "wrap", gap: 5 }}
            >
              <div style={styles.doorSelected}>
                <span className="mr-1">
                  {translate("dashboard.accounting.from")}{" "}
                </span>
                <input
                  type="date"
                  name="dateFrom"
                  className="inputDatePlatform"
                  onChange={(e) => setDefaultFromDate(e.target.value)}
                  defaultValue={defaultFromDate}
                  ref={register({ required: "From date is required" })}
                />

              </div>

              <div style={styles.doorSelected}>
                <span className="mr-1">
                  {translate("dashboard.accounting.to")}{" "}
                </span>
                <input
                  type="date"
                  name="endDate"
                  className="inputDatePlatform"
                  onChange={(e) => setDefaultToDate(e.target.value)}
                  defaultValue={defaultToDate}
                  ref={register({ required: "To date is required" })}
                />

              </div>

              <button
                type="button"
                className="btn btn-fucsia btn-sm"
                style={{ borderRadius: "50px" }}
                onClick={handleSubmit(onSubmitFilterMovement)}
                disabled={activeSearch}
              >
                <FaSearch />
              </button>
              <div className="btn-reset-platforms" onClick={() => resetFilters()}>
                {translate("global.close")}
              </div>
            </div>

          </form>
          {errors.endDate && <span> {errors.endDate.message} !</span>}
          {errors.dateFrom && <span> {errors.dateFrom.message} !</span>}
          {messageMovements}
        </>
      )}

    </div>
  );

  const buttonsActionsData = [
    {
      id: "open",
      label: translate("global.open"),
      icon: <FaDoorOpen />,
      keyAccess: "open_door_remote"
    },
    {
      id: "lock",
      label: translate("global.lock"),
      icon: <FaLock />,
      keyAccess: "lock_doors"
    },
    {
      id: "unlock",
      label: translate("global.unlock"),
      icon: <FaUnlock />,
      keyAccess: "unlock_doors"
    },
    {
      id: "movements",
      label: translate("global.movements"),
      icon: <FaHistory />,
      keyAccess: "view_movements_doors"
    },
  ];

  const buttonsActions = buttonsActionsData.map((item, index) => {
    // Verificación si el estatusPlatform es distinto de "ONLINE"
    if (estatusPlatform !== "ONLINE" && item.id !== "movements") {
      return null; // Si no está en "ONLINE" y el botón no es "movements", no se renderiza
    }

    // Condición de opacidad según las restricciones definidas
    const opacityValidate =
      item.id === "movements" && selectedDoor?.length !== 1
        ? 0.5
        : btnActionIsActive > 0
          ? 1
          : 0.5;

    // Manejador del click en cada botón
    const onClickHandler = () => {
      if (selectedDoor?.length > 1) setDateInputIsActive(false);

      if (opacityValidate === 1 && item.id !== "movements") {
        handleOptionSelection(item.id);
      }

      if (item.id === "movements" && selectedDoor?.length === 1) {
        handleOptionSelection(item.id);
        setDateInputIsActive(true);
      }

      setActionSelect(item.id);
    };

    // Renderizado del botón si el usuario tiene permisos
    return actionsPerms.includes(item.keyAccess) && (
      <div
        key={`action-` + item.id}
        style={{
          ...styles.itemButtons,
          opacity: opacityValidate,
          cursor: opacityValidate === 1 ? "pointer" : "not-allowed",
        }}
        className="btns-platforms-options"
        onClick={onClickHandler}
      >
        <span className="mr-2">{item.icon}</span>
        <span>{item.label}</span>
      </div>
    );
  });


  const legendData = [
    { label: translate("global.availables"), color: "available" },
    { label: translate("global.actived"), color: "actived" },
    { label: translate("global.reserved"), color: "reserved" },
    { label: translate("global.inactive"), color: "inactive" },
    { label: translate("global.Expired"), color: "expired" },
    { label: translate("global.occupied"), color: "occupied" },
  ];

  const legends = legendData.map((item, index) => (
    <div
      key={index}
      style={{
        ...styles.itemLegends,
        cursor: "help",
        borderColor: styles.colorsProperties[item.color],
      }}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <span
        className="mr-2"
        style={{
          ...styles.itemLegends.circle,
          background: styles.colorsProperties[item.color],
        }}
      ></span>
      <span
        style={{
          opacity: hoveredIndex === index ? 1 : 0,
          transition: "opacity 0.3s ease",
        }}
      >
        {hoveredIndex === index && item.label}
      </span>
    </div>
  ));

  const btnsNavigator = {
    back: modules?.length > 1 && moduleShowedRef.current > 0,
    next: modules?.length > 1 && moduleShowedRef.current < modules.length - 1,
  };

  const abortController = new AbortController();

  const handleSelectDoor = (door) => {
    setshowPackageInfo(null)
    if (selectedDoor?.length >= 1) setDateInputIsActive(false);
    if (selectedDoor?.length > 0) {
      if (selectedDoor?.includes(door)) {
        let newSelectedDoor = selectedDoor.filter((item) => item !== door);
        setSelectedDoor(newSelectedDoor);
        if (newSelectedDoor.length === 0) setBtnActionIsActive(false);
        return;
      }
      if (actionSelect === "movements") {
        setLoadingDoors({});
        setDateInputIsActive(true);
        setSelectedDoor([door]);
      } else {
        setDateInputIsActive(false);
        setSelectedDoor([...selectedDoor, door]);
      }
    } else {
      setSelectedDoor([door]);
      setBtnActionIsActive(true);
    }
  };

  const handleCopyText = (item) => {
    navigator.clipboard.writeText(item ?? "");
  };

  const renderValidateInfoPack = (pkg) => (
    <div>
      <div className="package-info-section">
        <abbr title="attribute">{translate("global.numPackage")}</abbr>:
        <div className="package-info-content">
          <span>
            <LuBox className="mr-1" />
            {pkg.packageNumber ?? ""}
          </span>
          <button
            type="button"
            onClick={() => handleCopyText(pkg.packageNumber)}
            className="btn-fucsia rounded p-1"
          >
            <RiIcons.RiFileCopyFill size={14} />
          </button>
        </div>
      </div>
  
      <div className="package-info-section">
        <abbr title="attribute">ID {translate("global.Package")}</abbr>:
        <div className="package-info-content">
          <span>{pkg.packageId ?? ""}</span>
          <button
            type="button"
            onClick={() => handleCopyText(pkg.packageId)}
            className="btn-fucsia rounded p-1"
          >
            <RiIcons.RiFileCopyFill size={14} />
          </button>
        </div>
      </div>
    </div>
  );
  
  const renderDoors = (doors) => {
    return doors.map((item, index) => {
      return <LockerDoor
        key={index}
        index={index}
        column1={column1}
        column2={column2}
        item={item}
        selectedDoor={selectedDoor}
        showPackageInfo={showPackageInfo}
        setshowPackageInfo={setshowPackageInfo}
        activeSearch={activeSearch}
        onLockerClick={handleSelectDoor}
        renderValidateInfoPack={renderValidateInfoPack} 
        styles={styles}
      />;
    });
  };

  const calculateModuleQuantity = (doorsArray) => {
    const moduleSet = new Set();

    doorsArray.forEach((door) => {
      if (door.boxitModuleId) {
        moduleSet.add(door.boxitModuleId);
      }
    });
    return Array.from(moduleSet);
  };

  const handleDoorsChange = (modDoors) => {
    const column1 = modDoors.filter((door) => door.columnModule === 1);
    setColumn1(column1);
    const column2 = modDoors.filter((door) => door.columnModule === 2);
    setColumn2(column2);
  };

  const handleModuleChange = (type) => {
    const modShowed = type ? moduleShowedRef.current + 1 : moduleShowedRef.current - 1;
    const modDoors = doors.filter(
      (door) => door?.boxitModuleId === modules[modShowed]
    );
    setModuleShowed(modShowed);
    moduleShowedRef.current = modShowed;
    handleDoorsChange(modDoors);
  };

  const handleActionDoors = (id, action) => {
    const abortController = new AbortController();
    try {
      const handleResponse = async () => {

        let numberDoor = doors
          .filter((objeto) => objeto.boxitDoorId === id)
          .map((obj) => obj.doorNumber)[0] || "-";

        const filterSelect = {
          doorId: id,
          actionId: action,
          dateFrom: defaultFromDate,
          dateTo: defaultToDate,
          numDoor: numberDoor
        };

        setActionsInProgress((prev) => prev + 1);

        setLoadingDoors((prevLoadingDoors) => ({
          ...prevLoadingDoors,
          [id]: 0,
        })); // status loading

        const response = await actionInPlatform(filterSelect);
        setActiveSearch(false)

        if (response?.code === 200) {
          setLoadingDoors((prevLoadingDoors) => ({ ...prevLoadingDoors, [id]: 1 })); // status success

          if (action === "movements") {
            if (response?.code === 200) {
              setListMovements(response.payload);
              setFilterMovementSelect(filterSelect)
              handleFocusMovements();
            } else {
              setFilterMovementSelect(null)
              setListMovements([]);
            }

          }

        } else if (response?.code === 404) {
          setLoadingDoors((prevLoadingDoors) => ({ ...prevLoadingDoors, [id]: 1 })); // status success
          setMessageMovements(translate("dashboard.accounting.message.NO_TRANSACTION"));
        } else if (response?.code === 416) {
          setLoadingDoors((prevLoadingDoors) => ({ ...prevLoadingDoors, [id]: 1 })); // status success
          setMessageMovements(translate("dashboard.accounting.message.MAX_DATE_INTERVAL"));
        } else {
          setLoadingDoors((prevLoadingDoors) => ({ ...prevLoadingDoors, [id]: 2 })); // status error
          setMessageMovements(translate("dashboard.packages.details.dropOff.receivePackage.NOT_FOUND"));
        }

        setModuleShowed(moduleShowedRef.current);
        setActionsInProgress((prev) => prev - 1);
      };

      handleResponse();
    } catch (error) {
      setLoadingDoors((prevLoadingDoors) => ({ ...prevLoadingDoors, [id]: 2 })); // status error
      console.error("Error action-" + action + " door", error);
      setActionsInProgress((prev) => prev - 1);
    }
    return () => abortController.abort();
  };

  const handleOptionSelection = (action) => {
    if (action === "movements") {
      setActiveSearch(true);
      handleActionDoors(selectedDoor[0], action);
    }
    if (action !== "movements") setDateInputIsActive(false);
      if (selectedDoor?.length > 0) {
        setBtnActionIsActive(false);
        selectedDoor.forEach((door) => {
          handleActionDoors(door, action);
        });
      }
  };

  const handleGetDoors = async () => {
    const response = await getDoorsByPlatform(
      idPlatform,
      abortController.signal
    );
    const grouped = await groupDoors(response);
    setDoors(grouped);
  };

const groupDoors = async (doorArray) => {
    if (doorArray?.length === 0) return [];
    const groupedDoors = {};
    doorArray?.forEach(door => {
        const key = `${door.boxitDoorId}`;
        if (!groupedDoors[key]) {
            groupedDoors[key] = {
                locationId: door.locationId,
                moduleId: door.moduleId,
                boxitLocationId: door.boxitLocationId,
                boxitModuleId: door.boxitModuleId,
                boxitDoorId: door.boxitDoorId,
                doorNumber: door.doorNumber,
                dimension: door.dimension,
                comPort: door.comPort,
                createdAt: door.createdAt,
                doorSequence: door.doorSequence,
                isActive: door.isActive,
                isAvailable: door.isAvailable,
                isScreen: door.isScreen,
                columnModule: door.columnModule,
                layer: door.layer,
                packageStatusId: door.packageStatusId,
                moduleNumber: Number(door.moduleNumber),
                packages: []
            };
        }
        if (groupedDoors[key].packages.length > 0 || door.packageId) {
            groupedDoors[key].packages.push({
                packageId: door.packageId,
                packageNumber: door.packageNumber,
            });
        }
    });

    const groupedDoorsArray = Object.values(groupedDoors);

    // Ordenamos por moduleNumber y luego por doorNumber
    const doorsOrdenate = groupedDoorsArray.sort((a, b) => {
        // Comparar por moduleNumber
        if (a.moduleNumber !== b.moduleNumber) {
            return a.moduleNumber - b.moduleNumber;
        }
        // Si moduleNumber es igual, comparar por doorNumber (conversión a número para evitar problemas)
        return Number(a.doorNumber) - Number(b.doorNumber);
    });

    return doorsOrdenate;
};

  const setDefaultDates = () => {
    const today = formatDate(todayDate);
    const firstDateOfMonth = formatDate(firstDayOfMonth);
    setDefaultFromDate(firstDateOfMonth);
    setDefaultToDate(today);
  };

  const resetFilters = () => {
    setLoadingDoors({});
    setSelectedDoor([]);
    setListMovements(null)
    setMessageMovements("");
    setActionSelect(null);
    // setActiveSearch(false);
    setDateInputIsActive(false);
    setModuleShowed(moduleShowedRef.current);
  };

  useEffect(() => {
    setDefaultDates();
    handleGetDoors();
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (doors?.length > 0) {
      const mods = calculateModuleQuantity(doors);

      const modDoors = doors.filter((door) => door?.boxitModuleId === mods[moduleShowedRef.current]);
      setModules(mods);
      setModuleShowed(moduleShowedRef.current || 0);

      handleDoorsChange(modDoors);
    }
  }, [doors]);


  useEffect(() => {
    handleGetDoors();

    if (actionSelect === "movements") {
      // setActiveSearch(false);
    } else if (actionsInProgress === 0) {
      const timeoutId = setTimeout(() => {
        resetFilters();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [actionsInProgress]);

  useEffect(() => {
    // AQUI SE HIZO CAMBIO: Forzar la actualización del componente al cambiar el módulo mostrado
    const updateComponent = () => {
      // Crear un efecto secundario para forzar la actualización
      setModuleShowed(moduleShowedRef.current);
    };
    updateComponent();
  }, [moduleShowedRef.current]); // AQUI SE HIZO CAMBIO: Dependencia de módulo mostrado

  return (
    <>
      {doors ? (
        doors.length > 0 ? (
          <div className="locker">
            {/* Actions Buttons */}
            <div className="mb-2" style={styles.legendActions}>{buttonsActions}</div>

          
            {/* Modules Pagination */}
             {modules?.length > 1 ? (
              <div
                className="mt-md-3 mb-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                  fontSize: "16px",
                  color: "white",
                }}
              >
                <div
                  className="mr-4"
                  onClick={() => btnsNavigator.back && handleModuleChange(0)}
                  style={{
                    cursor: "pointer",
                    opacity: btnsNavigator.back ? 1 : 0.2,
                  }}
                >
                  <FaCaretLeft color="white" size={50} />
                </div>
            
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                    fontSize: "16px",
                    color: "white",
                  }}
                >
                  <span>
                    {translate("global.module")} {moduleShowedRef.current + 1}
                  </span>
                </div>
            
                <div
                  className="ml-4"
                  onClick={() => btnsNavigator.next && handleModuleChange(1)}
                  style={{
                    cursor: "pointer",
                    opacity: btnsNavigator.next ? 1 : 0.2,
                  }}
                >
                  <FaCaretRight color="white" size={50} />
                </div>
              </div>
            ) : (
              <div className="mt-md-3 mb-2"></div>
            )}
            {/* Doors Module */}
         
           <div className="section-smart-locker doorsContainer">
              {column1.length > 0 && <div style={styles.doorsColumn}>{renderDoors(column1)}</div>}
              {column2.length > 0 && <div style={styles.doorsColumn}>{renderDoors(column2)}</div>}
            </div>
             
             {/* Selected Doors */}
             {selectedDoor?.length > 0 &&
              <div className="mb-2" style={styles.selectedDoor}>{modalCustome}</div>
            }

            <div className="mt-md-3" style={styles.legend}>{legends}</div>

          </div>
        ) :
          <div className="p-5 d-flex justify-content-center" style={{ fontSize: 14 }} >
            <LuAlertCircle size={20} className="mr-2" />{" "}
            {translate("global.NOT_AVAILABLE")}
          </div>
      ) : (
        <div className="loader center">
          <Spinner type={0} size={40} />
        </div>
      )}
    </>
  );
};

const styles = {
  colorsProperties: {
    occupied: "#ea580c",
    reserved: "#3AA0E8",
    available: "#61D565",
    inactive: "#393939",
    expired: "#CFCFCF",
    actived: "#E5BA00",
  },
  doorsColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: 8,
  },
  door: {
    padding: "1px",
    width: "100%",
    borderRadius: "px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  doorHover: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  doorStatus: {
    padding: "5px 10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "100%",
    borderRadius: "10px",
  },
  legendActions: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 10,
    maxWidth: "100%",
  },
  legend: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 10,
    maxWidth: "100%",
  },
  itemLegends: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #fff",
    padding: "10px 12px",
    borderRadius: "20px",
    color: "white",
    circle: {
      height: "12px",
      width: "12px",
      borderRadius: "50%",
      backgroundColor: "red",
    },
  },
  itemButtons: {
    padding: "10px 15px",
    background: "#393939",
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    color: "white",
    fontSize: "14px",
    isActive: {
      border: "2px solid #FFFFFF",
    },
  },
  selectedDoor: {
    // position:"absolute",
    top: "100px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    background: "rgb(57 57 57 / 31%)",
    gap: 10,
    marginTop: "15px",
    padding: "10px 10px",
    width: "90%",
    borderRadius: "8px",
    color: "white",
  },
  doorSelected: {
    display: "flex",
    alignItems: "center",
    padding: "2px 5px",
    borderRadius: "7px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  openDoor: {
    transition: "transform 0.5s ease-in-out",
    transform: "rotateY(90deg)",
  },
  inputDate: {
    width: "100px",
    background: "transparent",
    border: "1px solid gray",
    padding: "5px",
    color: "#adadad",
    borderRadius: "8px",
  },
};

export default SmartLocker;
