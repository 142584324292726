import { useCallback, useState } from "react";
import { getAvaliblePlatforms, setAvaliblePlatforms, getClientDomain, getToken } from "../utils/Common";

export const usePlatforms = () => {
    const [platforms, setPlatforms] = useState(getAvaliblePlatforms() ? getAvaliblePlatforms() : null);

    // const getPlatforms = useCallback(async (idCliente, signal) => {
    //     const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/client`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'authorization': `Bearer ${getToken()}`
    //         },
    //         signal: signal
    //     });
        
    //     const result = await response.json();
    //     if (result?.payload?.length > 0) {
    //         setAvaliblePlatforms(result.payload);
    //         setPlatforms(result.payload);
    //         return (result.payload);
    //     }
    //     else {
    //         setAvaliblePlatforms([])
    //         setPlatforms([]);
    //         return ([])
    //     }
    // }, [])

    const getPlatforms = useCallback(async (idCliente, signal) => {
        try {
            const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/client`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                signal
            });
    
            if (!response.ok) {
                throw new Error(`HTTP Error: ${response.status}`);
            }
    
            const result = await response.json();
    
            if (result?.payload?.length > 0) {
                setAvaliblePlatforms(result.payload);
                setPlatforms(result.payload);
                return result.payload;
            } else {
                setAvaliblePlatforms([]);
                setPlatforms([]);
                return [];
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                // Maneja otros errores según sea necesario (por ejemplo, puedes agregar lógica para mostrar un mensaje en la UI).
            }
            return []; // Retorna un array vacío en caso de error o aborto.
        }
    }, []);
    

    const getAllPlatforms = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain()}/platforms/s/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });
        
        const result = await response.json();
        if (result?.payload?.length > 0) {
            setAvaliblePlatforms(result.payload);
            setPlatforms(result.payload);
            return (result.payload);
        }
        else {
            setAvaliblePlatforms([])
            setPlatforms([]);
            return ([])
        }
    }, [])

    const getAllPlatformsClient = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/client`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });
        
        const result = await response.json();
        if (result?.payload?.length > 0) {
            setAvaliblePlatforms(result.payload);
            setPlatforms(result.payload);
            return (result.payload);
        }
        else {
            setAvaliblePlatforms([])
            setPlatforms([]);
            return ([])
        }
    }, []);

    const getAllPlatformsArching = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/arching`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });
        
        const result = await response.json();
        if (result?.payload?.length > 0) {
            setAvaliblePlatforms(result.payload);
            setPlatforms(result.payload);
            return (result.payload);
        }
        else {
            setAvaliblePlatforms([])
            setPlatforms([]);
            return ([])
        }
    }, [])

    const updateDetails = useCallback(async (dataToSend) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/update/details` , {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(dataToSend)
        });
        const result = await response.json();
        
        if (result?.payload?.length > 0) return (result.payload[0]);
        else return ([]);
    }, [])

    const getPlatformDetails = useCallback(async (idPlataforma, signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/platformDetails/${idPlataforma}` , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });
        
        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload[0]);
        else return ({ Result: 0});
    }, [])

    const openLocker = useCallback(async (dataToSend) => {
        const response = await fetch(`${getClientDomain()}/platforms/doors/openLocker` , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(dataToSend)
        });
        
        const result = await response.json();
        if (result) return (result);
        else return ([]);
    }, [])

    const createPlatform = useCallback(async (platformInfo, doorsProps) => {
        const response = await fetch(`${getClientDomain()}/platforms/platform/addNew` , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
           body: JSON.stringify({ platformInfo, doorsProps})
        });
        
        const result = await response.json();

        if (result) return (result);
        else return ([]);
    }, [])

    const getDoors = useCallback(async (idPlataforma, signal) => {
        const response = await fetch(`${getClientDomain()}/platforms/doors/available` , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify({ plataforma: idPlataforma }),
            signal: signal
        });
        
        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload);
        else return ([]);
    }, []);


    const getDoorsByPlatform = useCallback(async (idPlataforma, signal) => {
        const response = await fetch(`${getClientDomain(2)}/doors/location/${idPlataforma}` , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });
        
        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload);
        else return ([]);
    }, [])

    const openDoor = useCallback(async (dataToSend) => {
        const response = await fetch(`${getClientDomain(2)}/doors/open` , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(dataToSend)
        });
        
        const result = await response.json();
        if (result) return (result);
        else return ([]);
    }, []);

    const actionInPlatform = useCallback(async (dataToSend) => {
        const response = await fetch(`${getClientDomain(2)}/doors/actions/${dataToSend.actionId}` , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(dataToSend)
        });
        
        const result = await response.json();
        if (result) return (result);
        else return ([]);
    }, []);

    const rebootLocation = useCallback(async (dataToSend) => {
        const response = await fetch(`${getClientDomain(2)}/settings/reboot` , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(dataToSend)
        });
        
        const result = await response.json();
        if (result) return (result);
        else return ([]);
    }, []);

    const archingPlatformById = useCallback(async (signal, data) => {
        
        const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/arching` , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(data),
            signal: signal
        });
        
        const result = await response.json();
        if (result) return (result);
        else return ([]);
    }, [])

    

    return { platforms, getPlatforms, getAllPlatforms, getDoors, getPlatformDetails, updateDetails, openLocker, createPlatform, getAllPlatformsClient, getDoorsByPlatform, openDoor, actionInPlatform, rebootLocation, getAllPlatformsArching, archingPlatformById }
}