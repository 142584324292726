import { useState, useEffect, useRef, useContext } from "react";
import { translate } from "../utils/Common";
import "./index.css";
import { usePlatforms } from "../hooks/usePlatforms";
import AuthContext from "../contexts/auth";
import { GiPlatform } from "react-icons/gi";
import Spinner from "./Spinner";
import { useCallback } from "react";

const PlatformSearchList = ({
  register,
  errors,
  nameShow,
  idShow,
  readOnly,
  setValue,
  valueId,
  setPlatformSelect,
  platfSelect,
  idPlatform,
  handleIsBoxit,
  required,
  defaultPlatformId
}) => {
  const userContext = useContext(AuthContext);

  const [selectedPlatform, setSelectedPlatform] = useState();
  const [openSelect, setOpenSelect] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef(null);
  const { platforms, getPlatforms } = usePlatforms();
  const [plataformasList, setPlataformasList] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();

    const handlePlatforms = async () => {
        const platforms = await getPlatforms(userContext.user?.IdCliente, abortController.signal);
        setPlataformasList(platforms);
    }

    if (!platforms) handlePlatforms()
    else setPlataformasList(platforms)
    
    return () => abortController.abort();
}, [platforms, getPlatforms, userContext.user?.IdCliente]);

useEffect(() => {
  if (idPlatform !== translate("global.select") && idPlatform !== translate("global.noPlatforms")) {
      const isBoxitSelected = platforms?.filter(p => p.boxitLocationId === Number(idPlatform)) && platforms?.filter(p => p.boxitLocationId === Number(idPlatform))[0]?.IsBoxit;
      if (handleIsBoxit) handleIsBoxit(isBoxitSelected);
  }
}, [idPlatform, handleIsBoxit, platforms])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenSelect(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [wrapperRef]);

   // Este efecto se encarga de establecer la plataforma seleccionada por defecto
   useEffect(() => {

    if (defaultPlatformId && plataformasList) {

      if (plataformasList.length > 0) {

        const defaultPlatform = plataformasList.find(p => {
          return p.boxitLocationId === Number(defaultPlatformId)
        });

        if (defaultPlatform) {
          handleProviderSelection(defaultPlatform)
          setOpenSelect(false)
        }
      }
    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPlatformId, plataformasList]);


  const toggleSelect = () => {
    setOpenSelect((prevOpenSelect) => !prevOpenSelect);
    setSearchTerm("");
  };

  const handleProviderSelection = (selectedValue) => {
    setSelectedPlatform(selectedValue.boxitLocationId);
    setValue(valueId, selectedValue.boxitLocationId);
    setPlatformSelect && setPlatformSelect(selectedValue);
    toggleSelect();
  };

  const filteredProviders = plataformasList
    ? plataformasList.filter(
        (prov) =>
          idShow !== prov.boxitLocationId &&
          `${prov.locationName}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <div ref={wrapperRef}>
       <div className="input-group" style={{flexWrap:"nowrap"}}>

                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputName">
                                <GiPlatform size={16} />
                            </span>   
                        </div>

                        <div className="input-select-fake" onClick={toggleSelect}>
        <select
          readOnly={false}
          onClick={toggleSelect}
          defaultValue={selectedPlatform}
          style={{ pointerEvents: "none" }}
          {...register(valueId)}
          required={required}
        >
          <option key={0} className="font-small">
            {/* {nameShow ? nameShow : translate("global.select")}  */}
           
          {platfSelect && plataformasList && plataformasList.find(item => item.boxitLocationId === selectedPlatform)
        ? plataformasList.find(item => item.boxitLocationId === selectedPlatform).locationName 
        : translate("global.select") + " Boxit"}

          </option>
          {plataformasList ? (
            plataformasList?.map(
              (prov) =>
                selectedPlatform !== prov.boxitLocationId && (
                  <option
                    key={prov.boxitLocationId}
                    className="font-small"
                    value={prov.boxitLocationId}
                  >{`${prov.locationName}`}</option>
                )
            )
          ) : (
            <option className="font-small">
              {translate("global.noUsers")}
            </option>
          )}
        </select>
      </div>
        </div>
     

      {openSelect && (
        <div className="section-select-search">
          <div className="p-2">
            <input
              type="text"
              name="querySearch"
              className="form-control form-control-sm font-small"
              placeholder={translate("global.search")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="select-input__list">
            <ul>
              {filteredProviders ? (
                filteredProviders.length > 0 ? (
                  filteredProviders?.map(
                    (prov) =>
                      idShow !== prov.boxitLocationId && (
                        <li
                          key={prov.boxitLocationId}
                          className="font-small item-select-search"
                          value={prov.boxitLocationId}
                          onClick={() => handleProviderSelection(prov)}
                        >{`${prov.locationName}`}</li>
                      )
                  )
                )
                : (
                  <li className="font-small p-3">
                 {plataformasList ? (
                  <span>{`${translate("global.noPlatforms")} ${translate("global.withTheTerm")} "${searchTerm}"`} </span>
                 ):(
                  <div className="mx-2">
                    <Spinner type={1} size={15} />
                  </div>
                 )} 
                
                </li>
                )
              ) : (
                <li className="font-small p-3">
                  {translate("global.usersNotAvailable")}
                </li>
              )}
            </ul>
          </div>
        </div>
      )}

      {errors?.proveedor && (
        <span className="text-error">{errors.proveedor.message}</span>
      )}
    </div>
  );
};

export default PlatformSearchList;
