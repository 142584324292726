import React, { useEffect, useState } from "react";
import { useClient } from "../../hooks/useClient";
import { getNameRoleBli, translate } from "../../utils/Common";
import Spinner from "../Spinner";

const RoleBliSelect = ({register, itemDefault, isRequerid}) => {
  const { getRolesBli } = useClient();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const handleGetData = async () => {
      const abortController = new AbortController();
      try {
        const response = await getRolesBli(abortController.signal);
        setData(response);
      } catch (error) {
        console.error("Error fetching roles:", error);
      } finally {
        setLoading(false);
      }
    };

    handleGetData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div><Spinner type={1} size={15} /></div>;
  }

  // Validar si no hay datos
  if (data.length === 0) {
    return <p className="text-bold">{translate("global.noResults")}</p>;
  }

  return (
      <select
        className="form-control form-control-sm mt-2"
        defaultValue={itemDefault}
        name="typeRoleBli"
        ref={register}
        required={isRequerid}
      >
        <option value="">{translate("global.select")}</option>
        {data.length > 0 && data.map((item) => {
          return (
            <option value={item?.Id} key={item?.Id}>
              {getNameRoleBli(item?.Id)}
            </option>
          );
        })}
      </select>
  );
};

export default RoleBliSelect;
