import { useState, useContext } from 'react';
import { getClientDomain, getToken, translate } from '../../../../utils/Common';
import AuthContext from '../../../../contexts/auth';
import ModalContext from '../../../../contexts/modal';
import FormPackagesSearch from './FormSearch';
import PackageDetails from '../PackageDetails';
import PackagesResults from '../PackagesResults';
import ItemPackage from '../../../../components/ShowItems/ItemPackage';
import './index.css';

// vista principal para realizar tracking
const SearchPackages = () => {
    const userContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext);
    const [packagesFound, setPackagesFound] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemsShowing, setItemsShowing] = useState(0);

    const findPackages = async (data) => {
        try {
            let dataToSend = {};
            
            if (!userContext.user?.IsAdmin) dataToSend.IdUsuario = Number(userContext.user?.Ruc);
            else {
                if (data.usuario !== "Empresa" && data.usuario !== "No hay usuarios") dataToSend.IdUsuario = Number(data.usuario);
            }

            if (data.nombre) dataToSend.nombre = data.nombre.toString().trim().toUpperCase();
            if (data.apellido) dataToSend.apellido = data.apellido.toString().trim().toUpperCase();
            if (data.numeroPaquete) dataToSend.numeroPaquete = data.numeroPaquete.toString().trim().toUpperCase();
            if (data.numeroTracking) dataToSend.numeroTracking = data.numeroTracking.toString().trim();
            if (data.plataforma) dataToSend.plataforma = data.plataforma.toString().trim();
            if (data.estatusBoxit !== "-- Estatus Boxit --") dataToSend.estatusBoxit = data.estatusBoxit;
            if (data.tipo) dataToSend.tipo = data.tipo;
            if (data.fechaInicio) dataToSend.fechaInicio = data.fechaInicio;
            if (data.fechaFin) dataToSend.fechaFin = data.fechaFin;

            if (dataToSend.nombre || dataToSend.apellido || dataToSend.numeroPaquete
                 || dataToSend.numeroTracking || dataToSend.estatusBoxit || dataToSend.plataforma
                 || dataToSend.tipo || dataToSend.fechaInicio || dataToSend.fechaFin) {
                const response = await fetch(`${getClientDomain(2)}/dashboard/packages/find/tracking` , {
                    method: 'POST',
                    connection: 'keep-alive',
                    accept: 'application/json',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    body: JSON.stringify(dataToSend)
                });
                const result = await response.json();
                if (result.code === 200) {
                    if (result.payload) {
                        if (result.payload?.length > 0) {
                            setItemsShowing(result.payload.length <= 20 ? result.payload.length : 20);
                            setItemsFiltered(result.payload);
                            setPackagesFound(result.payload);
                        }
                        else modalContext.confirm(
                            translate("global.alert.attention"), 
                            translate("dashboard.packages.search.NOT_PACKAGE_FOUND"),
                            <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                            true);
                    }
                }
                else if (result.code === 404) modalContext.confirm(
                    'Atención!', 
                    'No se han encontrado paquetes con los parámetros que ingresaste.',
                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>Aceptar</button>,
                true);
                else modalContext.confirm(
                    translate("global.alerts.ops"), 
                    translate("dashboard.packages.search.ERROR_SEARCH"),
                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                    true);
            }
            else modalContext.confirm(
                translate("global.alert.attention"), 
                translate("dashboard.packages.search.ERROR_VALUE_SEARCH"),
                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                true);
        } catch (error) {
            console.log(error);
            modalContext.confirm(
                translate("global.alerts.ops"), 
                translate("dashboard.packages.search.ERROR_SEARCH"),
                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                true);
        }
    }

    const [selectedPackage, setSelectedPackage] = useState(null);

    const handleCancelBusqueda = () => {
        setPackagesFound([]);
        setItemsFiltered([]);
        setSelectedPackage(null);
    }

    return (
        <>
            { packagesFound.length === 0 ?
                <FormPackagesSearch onFindPackage={ findPackages } handleCancelBusqueda={handleCancelBusqueda} />
                :
                (!selectedPackage ? 
                    <PackagesResults
                        itemsArray={ packagesFound }
                        itemsFound={ itemsFiltered }
                        itemsShowing={itemsShowing}
                        itemName={translate("global.packages")}
                        itemSearch={translate("global.packages")}
                        setItemsShowing={setItemsShowing}
                        setItemsFiltered={setItemsFiltered}
                        setSelectedPackage={setSelectedPackage}
                        handleCancelBusqueda={ handleCancelBusqueda } 
                        parentItemPackage={ItemPackage}
                        typeExport={"packages"}
                        filterType={"package"}
                       

                        />
                    : 
                    <PackageDetails 
                        selectedPackage={ selectedPackage }
                        packagesFound={ packagesFound }
                        handleSelectedPackage={ setSelectedPackage }
                        handleCancelBusqueda={ handleCancelBusqueda } />
                )
            }
        </>
    );
}

export default SearchPackages;