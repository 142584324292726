import React, { useEffect, useState } from "react";
import "./index.css";
// icons
import * as FaIcons from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { useClient } from "../../../hooks/useClient";

// components
import { translate } from "../../../utils/Common";
import SearchFilters from "../../../components/SearchFilters";
import ShowCanvasFilters from "../../../components/ShowCanvasFilters";
import ItemClientsCanvas from "../../../components/ShowCanvasFilters/ItemClientsCanvas";
import { verifyHideCountryItems } from "../../../utils/Common";
import { IoFilter } from "react-icons/io5";


const Clients = () => {
  const { getClientsByFilter } = useClient();
  const [listClient,setListClient] = useState(null);
  const [openSearch,setOpenSearch] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [actualOption, setActualOption] = useState("");
  const [platformSelect, setPlatformSelect] = useState(null);

  const parametersSearch = [
    {
      id: "name",
      label: "Nombre",
    },
    {
      id: "lastName",
      label: "Apellido",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "clientId",
      label: "ID Cliente",
    },
    // {
    //   id: "plataforma",
    //   label: "Plataforma",
    // },
  ];

  const [configFilter, setConfigFilter] = useState({
    totalItems: 0,
    totalPages: 1,
    itemsPerPage: 51,
    currentPage: 1
  });

  const abortController = new AbortController();
    const handleGetClients = async (data) => {
      setListClient(null);
      const response = await getClientsByFilter(abortController.signal, data);
      
      setListClient(response?.itemsFind);
      setConfigFilter(response);
      
    };

    useEffect(() => {
      handleGetClients(configFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const data = {
        ...selectedFilters.reduce((acc, filter) => {
          acc[filter.id] = filter.searchTerm;
          return acc;
        }, {}),
        // plataforma: platformSelect?.boxitLocationId,
        ...configFilter,
        currentPage: actualOption === "GO_TO_PAGE" ? configFilter.currentPage : 1 ,
      };
  
      handleGetClients(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters, configFilter.currentPage, platformSelect]);
    

    const goToPage = (page) => {
      const config = { ...configFilter, currentPage: page };

      setConfigFilter(config);
      setActualOption("GO_TO_PAGE");
    };

    const handleViewSearch = () => {
      setOpenSearch(!openSearch);
    }

    const handleResetAllFilters = () => {
      setSelectedFilters([]);
      handleViewSearch();
      // setPlatformSelect(null);
    }

  return (
    <>
      <div style={{ padding: "25px" , position:"sticky",top:"0",backgroundColor: "rgba(255, 255, 255, 0.5)",backdropFilter:"blur(8px)", zIndex:15}}>
        <div className="header-filter-box">

          <div className="text-left d-flex" style={{alignContent:"center"}}>
            <h5 style={{color:"#424996"}}>
              <FaIcons.FaUserFriends size={18} className="mr-2" />
              {translate("global.clients")}
            </h5>
            
            <span className="ml-3 mt-1">{configFilter.totalItems?.toLocaleString('en-US')} Total</span>
          </div>

          <div className="d-flex" style={{ gap: "20px", position:"relative" }}>
       {verifyHideCountryItems(["us"]) &&(
          <div>
              <a href="/dashboard/clients/create" className="btn btn-fucsia">
                <IoMdPersonAdd className="mr-2" />
                {translate("global.createClient")}
              </a>
            </div>
          )}
            <div className="d-flex" >
              <button className="btn btn-white" onClick={() => handleViewSearch()}>
              {selectedFilters.length > 0 && <span class="badge mr-1" style={{background:"#424996", color:"white"}}>{selectedFilters.length}</span>}
                {translate("global.filters")}
                <IoFilter className="ml-2" />
              </button>

            
            {
              openSearch && 
              <div  style={{position:"absolute", right:"0", top:"60px", background:"#fff", padding:"25px", boxShadow:"0px 0px 10px rgba(92, 92, 92, 0.211)" , borderRadius:"16px", zIndex:99}}>
              <button
                  type="button"
                  className="btn btn-sm"
                  style={{position:"absolute", top:"-5px", right:"0", color:"white", background:"gray", padding:"0 5px", borderRadius:"50px"}}
                  onClick={() => handleViewSearch()}
                >
                  &times;
            </button>
                <SearchFilters parameters={parametersSearch} viewPlatforms getClient={handleGetClients} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} setActualOption={setActualOption} platformSelect={platformSelect} setPlatformSelect={setPlatformSelect} handleResetFilters={handleResetAllFilters} />
            </div>
            }  

            <div>
                <button className="btn btn-white ml-2" onClick={() => goToPage(configFilter.currentPage - 1)} disabled={configFilter.currentPage === 1 || listClient === null}>
                  {"<"}
                </button>

                <button className="btn btn-white ml-2" onClick={() => goToPage(configFilter.currentPage + 1)} disabled={configFilter.currentPage === configFilter.totalPages || listClient === null}>
                  {">"}
                </button>
              </div>

          <div className="d-flex align-items-center ml-2">
            <span>{configFilter.currentPage} {translate("global.of")} {configFilter.totalPages}</span>
          </div>
            

            </div>

        
            
          </div>
        </div>
      </div>

      <div style={{ margin: "15px" }}>
        <ShowCanvasFilters
          itemArray={listClient}
          Item={(props) => <ItemClientsCanvas {...props} />}
          noItemsFound={translate("global.noResults")}
          actualPage={1}
          itemsShowing={listClient?.length}
        />
      </div>
    </>
  );
};

export default Clients;
