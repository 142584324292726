import { getClasificacion, translate } from "../utils/Common";
import { format } from 'date-fns';

export const exportConfig = {
    packages: {
        columns: [
            { title: translate("global.numPackage"), dataKey: "nroPaquete" },
            { title: translate("global.numTracking"), dataKey: "nroTracking" },
            { title: "ID " + translate("global.client"), dataKey: "idCliente" },
            { title: translate("global.client"), dataKey: "cliente" },
            { title: translate("global.sort"), dataKey: "clasificacion" },
            { title: translate("global.door"), dataKey: "identificacion" },
            { title: "Boxit", dataKey: "plataforma" }
        ],
        mapData: (item, userType) => ({
            nroPaquete: item.Nro_Id || item.NumeroIdentificacion || '',
            nroTracking: item.NumeroTracking || '',
            idCliente: userType === 1 ? `B${item.IdCliente || ''}` : item.IdCliente || '',
            cliente: item.Cliente || '',
            clasificacion: item.Clasificacion ? getClasificacion(item.Clasificacion) : '',
            identificacion: item.Identificacion || 'S.P.',
            plataforma: item.Plataforma || item.Direccion || '-'
        }),
        fileNamePrefix: {
            [translate("global.reservations")]: "report",
            [translate("global.deliveries")]: "guide",
            default: "report"
        },
        reportName: {
            [translate("global.reservations")]: translate("global.packagesToReserve"),
            [translate("global.deliveries")]: translate("global.packagesToDeliver"),
            default: translate("global.packageSearch")
        },
        reportDescription:  translate("global.exportPDF.title.list.Search")
    },
    alertedPackages: {
        columns: [
            { title: translate("global.numPackage"), dataKey: "nroPaquete" },
            { title: translate("global.numTracking"), dataKey: "nroTracking" },
            { title: "ID " + translate("global.client"), dataKey: "idCliente" },
            { title: translate("global.client"), dataKey: "cliente" },
            { title: translate("global.status.package.alerted.DateAssigned"), dataKey: "fecha" },
            { title: "Boxit", dataKey: "plataforma" }
        ],
        mapData: (item, userType) => {
            
            const formatFecha = (fechaISO) => {
                if (!fechaISO) return ''; // Si no hay fecha, devolver un string vacío
        
                const [year, month, day] = fechaISO.split('-'); // Desestructurar la fecha
                return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`; // Retornar en formato 'dd/MM/yyyy'
            };
            const fechaEstimada = item.FechaEstimada ? item.FechaEstimada.split('T')[0] : ''; // Obtener la fecha sin la hora
            const fechaFormateada = formatFecha(fechaEstimada);
            
            //const formattedFechaEstimada = item.FechaEstimada ? format(new Date(item.FechaEstimada), 'dd/MM/yyyy') : '';
            
            return {
                nroPaquete: item.NumeroPaquete ?? '-',
                nroTracking: item.NumeroTracking || '',
                idCliente: userType === 1 ? `B${item.IdCliente || ''}` : item.IdCliente || '',
                cliente: item.NombreCompleto || '',
                plataforma: item.Plataforma || item.Direccion || '-',
                fecha: fechaFormateada || '-'
            };
        },
        fileNamePrefix: {
            [translate("global.reservations")]: "report",
            [translate("global.deliveries")]: "guide",
            default: "report"
        },
        reportName: {
            [translate("global.reservations")]: translate("global.packagesToReserve"),
            [translate("global.deliveries")]: translate("global.packagesToDeliver"),
            default: translate("global.packageSearch.alerted")
        },
        reportDescription: translate("global.exportPDF.title.list.Alerts")
    },
    platforms: {
        columns: [
            { title: "Nombre Plataforma", dataKey: "nombrePlataforma" },
            { title: "Ultimo Arqueo", dataKey: "lastArching" },
            { title: "Monto total", dataKey: "amountTotal" },
            { title: "Total de Billetes", dataKey: "totalBilletes" }
        ],
        mapData: (item) => ({
            nombrePlataforma: item.locationName || '',
            lastArching: item.Last_Cash_Collection || '',
            amountTotal: item.Cash || "$0.00",
            totalBilletes: item.TotalBilletes || 0
        }),
        fileNamePrefix: {default: "platform"},
        reportName: {default: translate("global.platformReport")},
        reportDescription: "LISTA DE PLATAFORMAS"
    },
    archingPlatforms: {
        columns: [
            { title: "Nombre Plataforma", dataKey: "nombrePlataforma" },
            { title: "Ultimo Arqueo", dataKey: "lastArching" },
            { title: "Monto total", dataKey: "amountTotal" },
            { title: "$1.00", dataKey: "billete1" },
            { title: "$5.00", dataKey: "billete5" },
            { title: "$10.00", dataKey: "billete10" },
            { title: "$20.00", dataKey: "billete20" },
            { title: "$50.00", dataKey: "billete50" },
            { title: "$100.00", dataKey: "billete100" },
            { title: "Total de Billetes", dataKey: "totalBilletes" }
        ],
        mapData: (item) => ({
            nombrePlataforma: item.locationName || '',
            lastArching: item.Last_Cash_Collection || '',
            amountTotal: item.Cash || "$0.00",
            billete1: item.AceptorBilletesUno || 0,
            billete5: item.AceptorBilletesCinco || 0,
            billete10: item.AceptorBilletesDiez || 0,
            billete20: item.AceptorBilletesVeinte || 0,
            billete50: item.AceptorBilletesCincuenta || 0,
            billete100: item.AceptorBilletesCien,
            totalBilletes: item.TotalBilletes || 0
        }),
        fileNamePrefix: {default: translate("global.platformArchingReport")},
        reportName: {default: translate("global.platformArchingReport")},
        reportDescription: "LISTA DE PLATAFORMAS"
    },
    accounting: {
        columns: [
            // Define las columnas específicas para accounting
        ],
        mapData: (item) => ({
            // Define el mapeo específico para accounting
        }),
        fileNamePrefix: "accounting",
        reportName: translate("global.accountingReport")
    },

    // Añade más configuraciones según sea necesario
};